<script>
import newResourceRequest from "@/components/addNewResReq.vue";
import AddNewEvent from "@/components/addNewEvent.vue";
import EditEventDialog from "@/components/editEventDialog.vue";
import DutylogDialog from "@/components/dutylogReport.vue";
import SitrepDialog from "@/components/sitrepDialog.vue";
import AddNewEventStatus from "@/components/addNewEventStatus.vue";
import {mapGetters} from "vuex";

export default {
  name: 'events',
  value: 'Events',
  components: {AddNewEventStatus, SitrepDialog, DutylogDialog, EditEventDialog, AddNewEvent, newResourceRequest},
  props: {},
  data: () => ({
    layoutInline: true,
    eventStatus: 'Open',
  }),
  methods: {
    goToNewEvent: function() {
      this.$router.push("/oem/newevent");
    }
  },
  watch: {},
  mounted() {
  },
  computed: {
    ...mapGetters(['getEvents']),
    eventList() {
      console.log(JSON.stringify(this.getEvents));
      return this.getEvents
    },
  }
}
</script>
<template>
  <v-card
      variant="flat"
      color="white"
      min-width="500"
  >
    <v-card-title class="text-center"> Events </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-btn
              density="default"
              variant="outlined"
              @click="goToNewEvent"
          >ADD NEW EVENT
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-table density="comfortable">
            <thead>
            <tr>
              <th class="text-left">
                Event name
              </th>
              <th class="text-left">
                Type
              </th>
              <th class="text-left">
                Status
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody id="tableBody">
            <tr v-for="ev in eventList">
              <td>{{ ev['ename'] }}</td>
              <td>{{ ev['evtypename'] }}</td>
              <td> {{ ev['statustxt'] }} </td>
              <td class="pa-0 text-right d-flex justify-space-between align-center">
                <edit-event-dialog v-if="ev.status<4" :eid="ev.id" />
                <sitrep-dialog v-if="ev.status===0 || ev.status===1" :eid="ev.id" :ename="ev.ename" :estatus="ev.status" :etype="ev.evtype"/>
<!--                <add-new-event-status v-if="ev.status===2 || ev.status===1"/>-->
<!--                <dutylog-dialog mode="icon" :eid="ev.id"/>-->
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style>
#tableBody{
  height: fit-content;
}
</style>