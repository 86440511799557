<script>
import HicsIap from "@/components/hics-iap.vue";
import AllHazardsSitrep from "@/components/allHazardsSitrep.vue";
import {getSitrep, objectToFormData, saveData, uploadAttachmentToSitrep} from "@/services.js";

export default {
  components: {AllHazardsSitrep, HicsIap},
  methods: {
    closeDlg() {
      this.dialog = false;
    },
    async saveSitrep() {
      try {
        if (!!this.rfile) {
          this.rfileid = await uploadAttachmentToSitrep(this.rfile[0]);
        } else {
          this.rfileid = -1;
        }
        let xdata = {
          mode: "savefullsitrep",
          eventID: this.eid,
          prognosis: this.prognosis,
          impact: this.impact,
          leadAgency: this.leadAgency,
          scrollMsg: '',
          sitBrief: this.sitBrief,
          EOCphone: this.EOCphone,
          EOChours: this.EOChours,
          ICPaddr: this.ICPaddr,
          ICPlat: this.ICPlat,
          ICPlng: this.ICPlng,
          ICPstat: this.ICPstat,
          emDecl: this.emDecl,
          muniis: this.muniis,
          ptrans: this.ptrans,
          ptranstime: this.ptranstime,
          evac: this.evac,
          evactime: this.evactime,
          sip: this.sip,
          siptime: this.siptime,
          winddir: this.wdir,
          windspeed: this.wspeed,
          spsize: this.spsize,
          expsize: this.expsize,
          sptime: this.sptime,
          EClass: this.EClass,
          ECond: this.ECond,
          fileid: this.rfileid,
          promeas: this.protm,
          relEvents: this.relEvents.join(','),
          essF: this.essF.map(val => (val ? "1" : "0")).join("")
        }
        if (this.etype === 3) {
          xdata.spsize = this.expsize;
        }
        let result1 = await saveData(objectToFormData(xdata));
        if (!!result1['Status']) {
          if (result1['Status'] === 'OK') {
            this.closeDlg();
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getCurrentEvent() {
      try {
        const params = {'mode': 'eventsitrep', 'eid': this.eid};
        this.rfile = null;
        const data = await getSitrep(params);
        if (data['srData'] !== undefined) {
          Object.keys(data['srData']).forEach(key => {
            if (key === 'impact') {
              this[key] = data['srData'][key] || 0;
            } else if (key === 'prognosis') {
              this[key] = data['srData'][key] || 0;
            } else if (key === 'EClass') {
              this[key] = data['srData'][key] || 0;
            } else if (key === 'relEvents') {
              this[key] = data['srData'][key] || [];
            } else if (key === 'spsize') {
              if (this.etype === 1) {
                this.spsize = !!data['srData'][key];
              }
              if (this.etype === 3) {
                this.expsize = parseInt(data['srData'][key] || '0');
              }
            } else if (key === 'sptime') {
              this[key] = !!data['srData'][key];
            } else if (key === 'evac') {
              this[key] = data['srData'][key] || 0;
            } else if (key === 'sitBrief') {
              this[key] = data['srData'][key] || '';
            } else if (key === 'muniis') {
              this[key] = data['srData'][key] || '';
            } else if (key === 'essF') {
              this[key] = data['srData'][key] || [false,false,false,false,false,false,false,false,false,false];
            } else {
              this[key] = data['srData'][key]
            }
          });
        }
        if (data['eventList'] !== undefined) {
          this.relEventsList = [...data['eventList']];
        }
        console.log(JSON.parse(JSON.stringify(data)));
        this.eventName=this.ename
        this.closeDlg();
        this.dialog = true;
      } catch (e) {
        console.log(e);
      }
    }
  },
  data() {
    return {
      dialog: false,
      eventName: 'TestEvent',
      EClass: null,
      ECond: null,
      EOChours: null,
      EOCphone: null,
      ICPaddr: null,
      ICPlat: null,
      ICPlng: null,
      ICPstat: null,
      emDecl: null,
      essF: [false,false,false,false,false,false,false,false,false,false],
      evac :null,
      evactime: null,
      impact: null,
      leadAgency: null,
      muniis: null,
      prognosis: null,
      protm: null,
      ptrans: null,
      ptranstime: null,
      relEvents: [],
      revID: null,
      scrollMsg: null,
      sip: null,
      siptime: null,
      sitBrief: null,
      spsize: null,
      expsize: null,
      sptime: null,
      srID: null,
      wdir: null,
      wspeed: null,
      relEventsList:[],
      rfile: null,
    }
  },
  props: {
    eid: {
      type: Number,
      required: true
    },
    ename: {
      type: String,
      required: true
    },
    estatus: {
      type: Number,
      required: true
    },
    etype: {
      type: Number,
      required: true
    }
  },
  computed: {
    event() {
      return this.$store.getters.getEvent(this.eid);
    },
    eventStatusText() {
      const selected = this.$eventStatus.find(item => item.v === this.estatus);
      return selected ? selected.l : null; // Return the label text
    },
    concatenatedSelectedEvents() {
      return this.relEvents
          .map((v) => this.relEventsList.find((item) => item.v === v).l)
          .join(', ');
    },
  }
}
</script>
<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="80vw"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          density="compact"
          icon="mdi-file-cabinet"
          size="small"
          v-bind="props"
          variant="flat"
          @click="getCurrentEvent"
      >
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-left ma-3">
        <span class="text-h5"> Event {{ ename }} </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="pa-1">
            <v-row no-gutters>
              <v-col
                  class="pa-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="Event name"
                    variant="outlined"
                    v-model="eventName"
                    readonly
                    persistent-hint
                ></v-text-field>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="$eventImpact"
                    item-value="v"
                    item-title="l"
                    v-model="impact"
                    density="compact"
                    label="Event Impact"
                    persistent-hint
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="Event Status"
                    variant="outlined"
                    persistent-placeholder
                    :value="eventStatusText"
                    readonly
                ></v-text-field>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="$eventPrognosis"
                    item-value="v"
                    item-title="l"
                    density="compact"
                    label="Event Prognosis"
                    persistent-hint
                    variant="outlined"
                    v-model="prognosis"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="relEventsList"
                    item-value="v"
                    item-title="l"
                    density="compact"
                    label="Related events"
                    multiple
                    variant="outlined"
                    persistent-hint
                    v-model="relEvents"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index===0" class="custom-selection">
                      {{ concatenatedSelectedEvents }}
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="Lead Agency"
                    persistent-placeholder
                    v-model="leadAgency"
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pb-0">
              <v-col
                  class="pa-1 pt-0 pb-0 "
                  cols="6"
                  v-if="etype===1 || etype===2"
              >
                <v-text-field
                    density="compact"
                    label="Wind Direction"
                    persistent-placeholder
                    variant="outlined"
                    v-model="wdir"
                ></v-text-field>
              </v-col>
              <v-col
                  v-if="etype===2"
                  class="pa-1 pt-0 pb-0 mb-0 mt-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="$eclass"
                    item-value="v"
                    item-title="l"
                    density="compact"
                    label="Classification"
                    variant="outlined"
                    persistent-placeholder
                    v-model="EClass"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="pt-0 pb-0">
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
                  v-if="etype===2"
              >
                <v-text-field
                    density="compact"
                    label="Wind Speed"
                    persistent-placeholder
                    variant="outlined"
                    v-model="wspeed"
                ></v-text-field>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
                  v-if="etype===2"
              >
                <v-text-field
                    density="compact"
                    label="Condition"
                    variant="outlined"
                    persistent-placeholder
                    v-model="ECond"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col
                  class="mt-2 pa-1 pt-0 pb-0 text-left"
                  cols="2"
                  v-if="etype===1"
              >
                <span>Spill type: </span>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="2"
                  v-if="etype===1"
              >
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="Large Spill"
                    v-model="spsize"
                >
                </v-checkbox>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="2"
                  v-if="etype===1"
              >
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="Daytime"
                    v-model="sptime"
                >
                </v-checkbox>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
                  v-if="etype===3"
              >
                <v-select
                    density="compact"
                    v-model="expsize"
                    :items="$explosives"
                    item-value="v"
                    item-title="l"
                    label="Bomb size"
                    variant="outlined"
                >
                  <template v-slot:item="{ props, item }">
                    <v-list-item v-bind="props">
                      <template v-slot:prepend>
                        <img :src="item.raw.image" alt="icon" class="scaled-image" />
                      </template>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-1 pt-1 pb-2"
                  cols="12"
              >
                <v-textarea
                    persistent-placeholder
                    :auto-grow="false"
                    class="mt-0"
                    density="compact"
                    label="Situation brief"
                    rows="4"
                    variant="outlined"
                    v-model="sitBrief"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="pt-2 pb-0 mt-2">
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="EOC 24/7 Phone"
                    variant="outlined"
                    persistent-placeholder
                    v-model="EOCphone"
                ></v-text-field>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="Hours of operation"
                    variant="outlined"
                    persistent-placeholder
                    v-model="EOChours"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-2">
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="$eocstat"
                    item-value="v"
                    item-title="l"
                    density="compact"
                    label="Activation status"
                    persistent-placeholder
                    variant="outlined"
                    v-model="ICPstat"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="Address of Command Post"
                    persistent-placeholder
                    v-model="ICPaddr"
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-0">
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="$yesno"
                    item-value="v"
                    item-title="l"
                    density="compact"
                    label="Emergency declaration"
                    persistent-placeholder
                    v-model="emDecl"
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-file-input
                    append-inner-icon="mdi-paperclip"
                    clearable
                    density="compact"
                    label="Upload file"
                    prepend-icon=""
                    variant="outlined"
                    v-model="rfile"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
          <v-col
              class="pa-1 pt-0"
              cols="6"
          >
            <v-row>
              <v-col
                  class="d-flex flex-row pa-1 pt-0"
                  cols="6"
              >
                <hics-iap :iap-inc="eid.toString()"/>
              </v-col>
              <v-col
                  class="d-flex flex-row-reverse pa-1 pt-0"
                  cols="6"
              >
                <all-hazards-sitrep :eventid="eid" :hazeventname="eventName"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="mt-2 pa-1 pt-0  pb-0 text-center"
                  cols="12"
              >
                <span class="text-left font-weight-bold">Essential functions: </span>
                <span
                    class="text-left">Check all Essential functions considered fully operational for this Event: </span>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0 pl-2">
              <v-col cols="2" class="pa-0">
                <v-checkbox
                    density="compact"
                    hide-details
                    label="COG"
                    v-model="essF[0]"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="2" class="pa-0">
                <v-checkbox
                    density="compact"
                    hide-details
                    label="Power"
                    v-model="essF[1]"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="2" class="pa-0">
                <v-checkbox
                    density="compact"
                    hide-details
                    label="LE"
                    v-model="essF[2]"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="2" class="pa-0">
                <v-checkbox
                    density="compact"
                    hide-details
                    label="Fuel"
                    v-model="essF[3]"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="2" class="pa-0">
                <v-checkbox
                    density="compact"
                    hide-details
                    label="Fire"
                    v-model="essF[4]"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="2" class="pa-0">
              </v-col>
              <v-col cols="2" class="pa-0">
                <v-checkbox
                    density="compact"
                    hide-details
                    label="Water"
                    v-model="essF[5]"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="2" class="pa-0">
                <v-checkbox
                    density="compact"
                    hide-details
                    label="EMS"
                    v-model="essF[6]"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="2" class="pa-0">
                <v-checkbox
                    density="compact"
                    hide-details
                    label="Sewer"
                    v-model="essF[7]"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="2" class="pa-0">
                <v-checkbox
                    density="compact"
                    hide-details
                    label="MED"
                    value="MED"
                    v-model="essF[8]"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="2" class="pa-0">
                <v-checkbox
                    density="compact"
                    hide-details
                    label="PW"
                    v-model="essF[9]"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="2" class="pa-0">
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pt-0 pb-0 ma-2 mb-2 "
                  cols="12"
              >
                <v-textarea
                    :auto-grow="false"
                    class="mt-0"
                    density="compact"
                    label="County/Municipal issues"
                    persistent-placeholder
                    rows="4"
                    variant="outlined"
                    v-model="muniis"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-2 mr-0">
              <v-col
                  class="pt-0 pr-0 mt-0 mb-0 ma-2"
                  cols="6"
              >
                <v-textarea
                    auto-grow
                    class="mt-0 pr-0"
                    density="compact"
                    label="Public Transportation"
                    persistent-placeholder
                    rows="3"
                    variant="outlined"
                    v-model="ptrans"
                ></v-textarea>
              </v-col>
              <v-col
                  class="pt-0 pr-0 mt-0 mr-0 ma-2"
                  cols="5"
              >
                <v-text-field
                    class="pl-0 ml-0 mr-0 pr-0"
                    density="compact"
                    label="Date/Time"
                    persistent-placeholder
                    v-model="ptranstime"
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col
                  class="pt-0 pr-0 pb-0 ml-2"
                  cols="6"
              >
                <v-autocomplete
                    :items="$evaclist"
                    item-value="v"
                    item-title="l"
                    density="compact"
                    label="Evacuation Type"
                    persistent-placeholder
                    v-model="evac"
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pt-0 pr-0 mt-0 mr-0 ma-2"
                  cols="5"
              >
                <v-text-field
                    density="compact"
                    label="Date"
                    persistent-placeholder
                    variant="outlined"
                    v-model="evactime"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col
                  class="pt-0 pr-0 pb-0 ml-2 mt-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="['Yes','No']"
                    item-value="v"
                    item-title="l"
                    density="compact"
                    v-model="sip"
                    persistent-placeholder
                    label="Shelter-In-Place"
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pt-0 pb-0 pr-0 mt-0 mr-0 ma-2"
                  cols="5"
              >
                <v-text-field
                    density="compact"
                    label="Date/Time"
                    persistent-placeholder
                    v-model="siptime"
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col
                  class="pt-0 pb-0 ma-2 mb-2"
                  cols="12"
              >
                <v-textarea
                    :auto-grow="false"
                    class="mt-0"
                    density="compact"
                    label="Protective Measures"
                    persistent-placeholder
                    rows="3"
                    v-model="protm"
                    variant="outlined"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col
                  class="pt-0 pb-0"
                  cols="4"
              >
                <v-autocomplete
                    :items="['Yes','No']"
                    density="compact"
                    label="Open Shelters"
                    multiple=""
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pt-0 pb-0"
                  cols="4"
              >
                <v-autocomplete
                    :items="['Yes','No']"
                    density="compact"
                    label="Open Decon Centers"
                    multiple=""
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pt-0 pb-0"
                  cols="4"
              >
                <v-autocomplete
                    :items="['Yes','No']"
                    density="compact"
                    label="Open Reception Centers"
                    multiple=""
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
            class="ma-1 ml-0"
            size="default"
            variant="outlined"
            @click="closeDlg"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            class="ma-1 mr-0"
            size="default"
            variant="outlined"
            @click="saveSitrep"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.custom-selection {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.scaled-image {
  height: 40px;
  width: auto;
  object-fit: scale-down;
  padding-right: 5px;
}
</style>