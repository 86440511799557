<script>
import {mapGetters} from "vuex";
import {uploadAttachmentId, saveData, objectToFormData} from "@/services.js";

export default {
  methods: {
    async saveAndClose() {
      try {
        if (this.rfile && this.rfile.length > 0) {
          const fd = new FormData() ;
          uploadAttachmentId(this.rfile[0])
            .then(responseid => {
              if (responseid) {
                const frm = {
                  'fileid': responseid,
                  'owner': this.fowner,
                  'title': this.ftitle,
                  'visibility': this.selectedMunis,
                  'mode': 'savedbfile'
                };
                return saveData(objectToFormData(frm));
              } else {
                throw new Error('Something went wrong in document upload');
              }
            })
            .then(result1 => {
              if (result1['Status']==='OK') {
                this.closeDlg();
              } else {
                throw new Error('Something went wrong in document upload');
              }
            })
            .catch(error => {
              alert("Something went wrong in saving the document: " + error.message);
            });

        }
      } catch(error) {
        alert('Something went wrong in saving document');
      }
    },
    closeDlg: function() {
      this.dialog = false ;
    },
    selectionChanged: function () {
      console.log(JSON.stringify(this.selectedMunis))
    },
  },
  data: () => ({
    fowner: null,
    repotype: null,
    ftitle: null,
    rfile: null,
    ddate: null,
    dialog: false,
    selectedMunis: null,
  }),
  computed: {
    ...mapGetters(['getRecipientsA']),
    muniList() {
      let munilist = [];
      this.getRecipientsA.forEach( rec => {
        if (rec['group']!=='Neighbours') {
          munilist.push({'l': rec.Name, 'v': rec.Code});
        }
      })
      return munilist;
    },
    notEnoughData() {
      let flag = this.fowner && this.ftitle;
      flag = flag && this.rfile && (this.rfile.length>0);
      flag = flag && this.selectedMunis && (this.selectedMunis.length>0)
      return !flag;
    },
    concatenatedSelectedItems() {
      return this.selectedMunis
          .map((v) => this.muniList.find((item) => item.v === v).l)
          .join(', ');
    },
  }
}
</script>

<template>
    <v-dialog
        theme="light"
        v-model="dialog"
        persistent
        width="30vw"
    >
      <template v-slot:activator="{ props }">
        <v-btn
            v-if="['B','C','D','G'].includes($store.state._main_accessLevel)"
            variant="flat"
            icon = "mdi-plus"
            size="small"
            density="compact"
            v-bind="props"
        >
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-center mt-3">New Dashboard Document</v-card-title>
        <v-card-text>
          <v-row class="pa-0">
            <v-col
                class="pa-0"
                cols="12">
              <v-text-field
                  density="compact"
                  label="Document owner"
                  variant="outlined"
                  v-model="fowner"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col
                class="pa-0"
                cols="12">
              <v-text-field
                  v-model="ftitle"
                  density="compact"
                  label="Document Title"
                  variant="outlined"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col
                class="pa-0"
                cols="12">
              <v-file-input
                  v-model="rfile"
                  clearable
                  density="compact"
                  label="Document to Upload"
                  variant="outlined"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col
                class="pa-0"
                cols="12">
            <v-autocomplete
                :items="muniList"
                item-title="l"
                item-value="v"
                class="mr-2"
                density="compact"
                label="Select jurisdiction"
                multiple
                variant="outlined"
                hide-details
                v-model="selectedMunis"
                @update:model-value="selectionChanged"
            >
              <template v-slot:selection="{ item, index }">
                <div v-if="index===0" class="custom-selection">
                  {{ concatenatedSelectedItems }}
                </div>
              </template>
            </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
              class="ma-1 pa-0"
              variant="outlined"
              width="30%"
              @click="closeDlg"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              class="ma-1 pa-0"
              variant="outlined"
              width="30%"
              :disabled="notEnoughData"
              @click="saveAndClose"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<style scoped>
.custom-selection {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
</style>