<script>
import Dutylog from "./eventDbDutylog.vue";
import {getALog} from "@/services.js";

export default {
  name: "documents",
  components: {Dutylog},
  data:function() {
    return {
      showdutylog:false,
      dialogMaxWidth: "800px",
      icsroster:{},
      dutylog:[]
    }
  },
  methods: {
    async openALog(uid){
      await getALog(uid,this.$store.state._ICS214_eventId)
          .then(response => {
            this.icsroster=response.icsroster;
            this.dutylog=response.icsdutylog;
            this.showdutylog=true;
          })
    },
    closeALog() {
      this.showdutylog=false;
    }
  },
  computed: {
    homeUrl() {
      return this.$backendUrl;
    },
    docData() {
      return this.$store.state._edb_evRevisionList.filter(object => object.fid>0);
    }
  }
}
</script>
<template>
    <div class="notes">
        <h3>Documents</h3>
        <div class="doc-list">
            <table class="tab">
                <tbody>
                <tr>
                    <th>Rev</th>
                    <th>Time</th>
                    <th>User</th>
                    <th>Message / Document</th>
                </tr>
                <tr v-for="entry in docData">
                    <td class="td2">
                        <span>{{entry.revId}}</span>
                    </td>
                    <td class="td2">
                        <span>{{entry.sitreptime}}</span>
                    </td>
                    <td class="td2">
                        <span><a @click="openALog(entry.userid)">{{entry.user}}</a></span>
                    </td>
                    <td class="td2">
                        <span><a v-bind:href="homeUrl+'getFile.php?id='+entry.fid" target="_blank">{{entry.icsfile}}</a></span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <v-dialog v-model="showdutylog" :max-width="dialogMaxWidth">
          <v-card>
            <v-card-title>
              Duty Log
            </v-card-title>
            <v-card-text>
              <v-row><v-col cols="12" class="text-center text-h6">Duty Roster/Activity Log (ICS 214)</v-col></v-row>
              <v-row no-gutters>
                <v-col cols="6" class="text-left text-body-2">
                  <span class="font-weight-bold">Name:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.uname}}</span>
                </v-col>
                <v-col cols="6" class="text-left text-body-2">
                  <span class="font-weight-bold">Logged In as:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.umail}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="text-left text-body-2">
                  <span class="font-weight-bold">Start Operational Period:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.sop}}</span>
                </v-col>
                <v-col cols="6" class="text-left text-body-2">
                  <span class="font-weight-bold">Position:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.posit}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="text-left text-body-2">
                  <span class="font-weight-bold">End Operational Period:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.eop}}</span>
                </v-col>
                <v-col cols="6" class="text-left text-body-2">
                  <span class="font-weight-bold">Agency:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.uagency}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="text-left text-body-2">
                  <span class="font-weight-bold">Relief Name:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.rname}}</span>
                </v-col>
                <v-col cols="6" class="text-left text-body-2">
                  <span class="font-weight-bold">Relief Time:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.rtime}}</span>
                </v-col>
              </v-row>
              <v-row v-if="icsroster.res1!==''" no-gutters>
                <v-col cols="4" class="text-left text-body-2">
                  <span class="font-weight-bold">Resources Assigned:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.res1}}</span>
                </v-col>
                <v-col cols="4" class="text-left text-body-2">
                  <span class="font-weight-bold">ICS Position:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.icsp1}}</span>
                </v-col>
                <v-col cols="4" class="text-left text-body-2">
                  <span class="font-weight-bold">Home Agency:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.hage1}}</span>
                </v-col>
              </v-row>
              <v-row v-if="icsroster.res2!==''" no-gutters>
                <v-col cols="4" class="text-left text-body-2">
                  <span class="font-weight-bold">Resources Assigned:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.res2}}</span>
                </v-col>
                <v-col cols="4" class="text-left text-body-2">
                  <span class="font-weight-bold">ICS Position:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.icsp2}}</span>
                </v-col>
                <v-col cols="4" class="text-left text-body-2">
                  <span class="font-weight-bold">Home Agency:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.hage2}}</span>
                </v-col>
              </v-row>
              <v-row v-if="icsroster.res3!==''" no-gutters>
                <v-col cols="4" class="text-left text-body-2">
                  <span class="font-weight-bold">Resources Assigned:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.res3}}</span>
                </v-col>
                <v-col cols="4" class="text-left text-body-2">
                  <span class="font-weight-bold">ICS Position:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.icsp3}}</span>
                </v-col>
                <v-col cols="4" class="text-left text-body-2">
                  <span class="font-weight-bold">Home Agency:&nbsp;</span>
                  <span class="font-weight-regular">{{icsroster.hage3}}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <table id="win10b17">
                    <tr>
                      <th class="win10h1">User Name</th>
                      <th class="win10h2">Rev</th>
                      <th class="win10h3">Time</th>
                      <th class="win10h4">In/Out</th>
                      <th class="win10h5">Priority</th>
                      <th class="win10h6">Message</th>
                      <th class="win10h7">Document</th>
                    </tr>
                    <tr v-for="entry in dutylog">
                      <td class="win10h1">{{entry.uname}}</td>
                      <td class="win10h2">{{entry.revId}}</td>
                      <td class="win10h3">{{entry.mdatetime}}</td>
                      <td class="win10h4">{{parseInt(entry.in)?'IN':''}}{{parseInt(entry.out)?'OUT':''}}</td>
                      <td class="win10h5">{{parseInt(entry.prio)?'Pri':''}}{{(parseInt(entry.prio)+parseInt(entry.immed))===2?'/':''}}{{parseInt(entry.immed)?'Imm':''}}</td>
                      <td class="win10h6">{{entry.msg}}</td>
                      <td class="win10h7">{{entry.doc}}</td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="closeALog">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
.tab {
    width : 100%;
}
.td2 {
    font-size: 14px;
    padding : 5px 5px 5px 15px;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
}
.notes {
    display: flex;
    height: 100%;
    flex-direction: column;
}
.notes h3 {
    height: 25px;
}
tr:nth-child(even) {background: #CCC}
tr:nth-child(odd) {background: #FFF}
th {
    clear: both;
    vertical-align: top;
    font-size: 14px;
    text-align: left;
    background-color: #7c93c0;
    font-weight: bold;
    padding: 0 5px 0 5px ;
}
</style>
