<script>
import incidents911 from '@/components/911incidents.vue'
import notes911 from '@/components/911notes.vue'
import status911 from '@/components/911status.vue'
import {DragCol, DragRow, Resize} from "vue-resizer";
import iicepStore from "@/iicepStore.js";
import {mapActions} from "vuex";
import map911 from "@/components/911map.vue";

export default {
  name: 'db911',
  data:function(){
    return {
      notesList : [],
      statusList : [],
      incidentList : [],
      loadtime :'454545',
      dialog: false,
      iicepmap: null,
      drawman: null,
      markers: [],
      linepolymarker: [],
      latestAnnoId: 0,
      currentSymbol: 0,
      infoW : null,
      vm: null
    }
  },
  components: {
    map911,notes911,status911,incidents911,
    DragRow, DragCol,Resize
  },
  methods: {
    ...mapActions(['fetch911Data']),
/*
    showMlist:function(){
    },
    showPlist:function(){
    },
    hideLists:function(){
    },
    saveAnno:function() {
    },
    refreshOld:function(){
      let self=this ;
      apiClientSimple.get('./get911.php', {params: {'anno': this.latestAnnoId}})
          .then(response => {
            let json = response.data;
            if (json.Status === 'OK') {
              self.$parent.statusList=json.statuses;
              self.$parent.notesList=json.notes;
              if (json.data.length>0) {
                for (let i = 0; i < json.data.length; i++) {
                  let au=json.data[i].iresrc.replace('Active Units:','');
                  let itype='fireems';
                  if (au.indexOf('A')>-1) {
                    itype='ems';
                  }
                  if (au.indexOf('S')>-1){
                    itype='fire';
                  }
                  if ((au.indexOf('A')>-1)&&(au.indexOf('S')>-1)) {
                    itype='fireems';
                  }
                  json.data[i].icontype=itype ;
                }
              }
              self.$parent.incidentList=json.data.slice();
              let d = new Date();
              self.$parent.loadtime = d.toLocaleTimeString('en-US',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
            }
          })
    },

 */
    setMode:function(modeType){
    },
    refresh:function(){
      this.fetch911Data();
    },
  },
  mounted() {
    this.refresh();
    if (iicepStore.data._911Interval!=='') {
      clearInterval(iicepStore.data._911Interval);
      iicepStore.data._911Interval = '';
    }
    iicepStore.data._911Interval = setInterval(this.refresh, 120000);
  }
}
</script>
<template>
    <v-container
        fluid
        class="fill-height pa-0"
    >
        <DragCol height="100%" width="100%" class="limitheight" slider-bg-color="#385F73">
          <template #left>
            <DragRow height="100%" width="100%" slider-bg-color="#385F73">
              <template #top>
                <incidents911
                    :incidentData="this.$parent.incidentList"
                ></incidents911>
              </template>
              <template #bottom>
                <notes911
                    :notesData="this.$parent.notesList">
                ></notes911>
              </template>
            </DragRow>
          </template>
          <template #right>
            <DragRow height="100%" width="100%" slider-bg-color="#385F73">
              <template #top>
                <div id="layout">
                  <map911>
                  </map911>
                </div>
              </template>
              <template #bottom>
                <status911
                    :statusData="this.$parent.statusList">
                ></status911>
              </template>
            </DragRow>
          </template>
        </DragCol>
    </v-container>
</template>
<style scoped>
#layout {
  display: flex;
  flex-direction: column;
  height: 100%; /* Viewport height */
  width: 100%;  /* Viewport width */
  margin:0;
}
.limitheight {
  max-height: 100%;
}
</style>
<style>
.drager_left > div > .drager_row > .drager_top > div {
  overflow-y: scroll !important;
}
.drager_bottom > div {
  overflow-y: scroll !important;
}
</style>