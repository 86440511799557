<script setup>
import mapwin from "@/components/mmap.vue";
import activityLog from "@/components/activityLog.vue";
</script>

<template>
    <v-navigation-drawer theme="dark" rail expand-on-hover permanent location="left">
      <v-list density="compact" nav>
        <v-list-item
            prepend-icon="mdi-record-circle"
            value="Events"
            title="Events"
            @click="goToEvents"
        >
        </v-list-item>
<!--        <v-list-item-->
<!--            prepend-icon="mdi-forum"-->
<!--            value="Activity_Log"-->
<!--            title="Activity Log"-->
<!--        >-->
          <activity-log
              button-density="default"
              button-label="Activity Log"
              button-size="default"
              eventid="0"
              actmode="list-item"
          ></activity-log>
<!--        </v-list-item>-->
        <v-list-item
            prepend-icon="mdi-circle-double"
            value="Incidents"
            title="Incidents"
            @click="goToIncidents"
        >
        </v-list-item>
        <v-list-item
            prepend-icon="mdi-wrench"
            value="ResourceRequests"
            title="Resource Requests"
            @click="goToResReqs"
        >
        </v-list-item>
        <v-list-item
            prepend-icon="mdi-microsoft-azure"
            value="Shelters"
            title="Shelter management"
            @click="goToShelters"
        >
        </v-list-item>
      </v-list>
      <v-list density="compact" nav>
        <v-list-item
            prepend-icon="mdi-logout"
            value="Dashboard"
            title="Dashboard"
            @click="goToDashboard()"
        >
        </v-list-item>
      </v-list>
        <template v-slot:append>
          <div>
            <v-btn
                icon="mdi-chevron-left"
                variant="text"
                @click.stop="resizePanel"
            ></v-btn>
          </div>
        </template>
    </v-navigation-drawer>
    <v-navigation-drawer
        location="left"
        permanent
        :width="sidePanel"
    >
      <router-view></router-view>
    </v-navigation-drawer>
    <v-bottom-navigation>
      <v-btn value="recent">
        Support request
      </v-btn>
      <v-btn value="favorites">
        Help
      </v-btn>
    </v-bottom-navigation>
    <div id="layout">
      <mapwin/>
    </div>
</template>
<script>
  export default {
    name: 'oem_main',
    props: {},
    data: () => ({
      rail:true,
      drawer:true,
      isClicked: false,
    }),
    methods: {
      resizePanel: function () {
        this.$store.commit('invertCollapsed');
      },
      goToDashboard: function(){
        let self = this;
        self.$router.push({name:"home"})
      },
      goToShelters: function(){
        let self = this;
        self.$router.push({name:"shelters"})
      },
      goToResReqs: function(){
        let self = this;
        self.$router.push({name:"resreqs"})
      },
      goToIncidents: function(){
        let self = this;
        self.$router.push({name:"incidents"})
      },
      goToEvents: function(){
        let self = this;
        self.$router.push({name:"events"})
      },
    },
    watch: {
    },
    computed: {
      sidePanel()  {
        return (this.$route.meta.mapSidePanel && !this.$store.getters.getCollapsed)?700:2;
      }
    },
    mounted() {
    }
  }
</script>
<style scoped>
.icon-sprite {
  width: 50px;
  height: 50px;
  object-fit: none;
  object-position: 0 0;
}
.leftc {
  max-width: 52px !important;
}
.icon-sprite.clicked {
  object-position: 0 -50px;
}
.squarecls {
  display: inline-block;
  height: 20px;
  width: 20px;
}
#layout {
  display: flex;
  flex-direction: column;
  height: 100%; /* Viewport height */
  width: 100%;  /* Viewport width */
  margin:0;
}
</style>
