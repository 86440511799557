<script>
import {mapState} from "vuex";
import {saveData, uploadAttachmentToSitrep, objectToFormData} from "@/services.js";

export default {
  props: ['buttonLabel', 'buttonDensity', 'buttonSize', 'actmode', 'eventid'],
  methods: {
    async submitMessage() {
      try {
        console.log('1');
        if (!!this.rfile) {
          console.log('rfile :', this.rfile);
          this.rfileid = await uploadAttachmentToSitrep(this.rfile[0]);
          console.log('rfileid :', this.rfileid);
        } else {
          this.rfileid = -1;
          console.log('rfileid2 :', this.rfileid);
        }
        console.log('2');
        let xdata = {
          mode: "saveicsmsgN",
          eventid: this.selectedEvent,
          msgText: this.msgtext,
          msgDir: this.msgdir,
          priority: this.msgpri?1:0,
          immed: this.msgimm?1:0,
          copycnty: this.msgmuni,
          fileId: this.rfileid
        }
        console.log('3');
        let result1 = await saveData(objectToFormData(xdata));
        if (!!result1['Status']) {
          if (result1['Status']==='OK') {
            this.closeDlg();
          }
        }
        console.log('4');
      } catch (e) {
        alert(e.message);
      }

    },
    initEList() {
      if (this.eventid > 0) {
        this.selectedEvent = this.eventid;
      } else {
        this.selectedEvent = '';
      }
      this.msgtext= '';
      this.msgdir = '1';
      this.msgpri = false;
      this.msgimm = false;
      this.msgmuni = true;
      this.rfile = null;
    },
    openDlg() {
      this.dialog = true;
    },
    closeDlg() {
      this.dialog = false;
    }
  },
  data: () => ({
    dialog: false,
    selectedEvent: '',
    msgtext: '',
    msgdir: '',
    msgpri: '',
    msgimm: '',
    msgmuni: '',
    rfile: null,
    rfileid: null,
  }),
  computed: {
    ...mapState(['loggedUserObj']),
    cansubmit() {
      if (this.selectedEvent !== '' && this.msgtext !== '') {
        return true;
      }
    },
    publabel() {
      if (this.loggedUserObj['CCODE'] !== this.loggedUserObj['MCODE']) {
        return 'Publish to County SitRep Activity Logs';
      } else {
        return 'Publish to Municipal SitRep Activity Logs';
      }
    },
    eventList() {
      const evx = [];
      this.$store.state.events.forEach(ev => {
        if (ev['status'] === 1 || ev['status'] === 2) {
          evx.push({'l': ev['ename'], 'v': ev['id']});
        }
      })
      return evx;
    }
  }
}
</script>
<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="50vw"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          v-if="actmode==='button'"
          id="ActBtn"
          slot="activator"
          :density="buttonDensity"
          :size="buttonSize"
          v-bind="props"
          variant="outlined"
          @click="initEList"
      > {{ buttonLabel }}
      </v-btn>
      <v-list-item
          v-if="actmode==='list-item'"
          prepend-icon="mdi-forum"
          title="Activity Log"
          value="Activity_Log"
          @click="openDlg"
      >
      </v-list-item>
    </template>
    <v-card theme="light">
      <v-card-title class="text-center mt-3">Activity Log</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col class="pa-0 pb-2" cols="12">
            <v-autocomplete
                v-model="selectedEvent"
                :items="eventList"
                density="compact"
                hide-details="true"
                item-title="l"
                item-value="v"
                label="Event"
                variant="outlined"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-textarea
                id="messageArea"
                v-model="msgtext"
                auto-grow
                clearable
                density="default"
                label="Enter message here.."
                required
                rows="1"
                variant="outlined"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-radio-group v-model="msgdir" :inline="true">
              <v-radio
                  label="IN"
                  value="1"
              ></v-radio>
              <v-radio
                  label="OUT"
                  value="0"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <div class="d-flex align-center">
              <v-checkbox
                  id="checkbox1"
                  v-model="msgpri"
                  density="compact"
                  label="Priority"
              >
              </v-checkbox>
              <v-checkbox
                  id="checkbox2"
                  v-model="msgimm"
                  density="compact"
                  label="Immediate"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
          </v-col>
          <v-col cols="6">
            <v-checkbox
                id="checkbox3"
                v-model="msgmuni"
                :label="publabel"
                density="compact"
                hint="This Activity Log Message,when checked, will publish to the running log of the County as the Lead or supporting Agency engaged in management of this Major Event"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-file-input
                append-inner-icon="mdi-paperclip"
                clearable
                density="compact"
                label="File input"
                prepend-icon=""
                variant="outlined"
                v-model="rfile"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="blue-darken-1"
            variant="outlined"
            @click="submitMessage"
            :disabled="!cansubmit"
        >Submit
        </v-btn> <!-- submit button -->
        <v-btn
            id="closeButton"
            color="blue-darken-1"
            density="default"
            max-width="15%"
            variant="outlined"
            @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
#closeButton {
  width: 10vw
}

#messageArea {
  height: 5vh
}

#checkboxes {
  height: 1vh
}

#ActBtn {
}
</style>