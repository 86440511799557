<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css"
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import {mapState} from "vuex";
mapboxgl.accessToken = 'pk.eyJ1Ijoic2RhbGFrb3YiLCJhIjoiY2o1eGxvdnRzMDVhOTJ4bnczd3lpMTRiMiJ9.lb016P2ofij1axIWoobBCQ';
import {mapEdbConfig} from "@/mapEdbConfig.js";

class MapButton {
  constructor(onClick, img, id) {
    this.onClick = onClick;
    this.imgurl = img;
    this.btnid = id;
  }
  setIcon(img) {
    this.imgurl = img;
    const buttonImg = document.querySelector(`#${this.btnid} img`);
    if (buttonImg) {
      buttonImg.src = img;
    }
  }
  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    const button = document.createElement('button');
    button.id = this.btnid;
    const buttonImg = document.createElement('img');
    buttonImg.src = this.imgurl;
    button.appendChild(buttonImg);
    button.onclick = this.onClick;
    this.container.appendChild(button);
    return this.container;
  }
  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

export default {
  name: 'mapnodraw',
  data: () => ({
    resizeObserver: null,
    initComplete1: false,
    initComplete2: false,
    maplayers: [],
    popup:null,
    current_map: 'satellite',
    btn_icons: {
      'street': './assets/satellite.png',
      'satellite': './assets/street.png'
    },
    mapTypeControl: null,
    mapLayerControl: null,
    sourcedefs: mapEdbConfig.sourcedefs,
    layerdefs: mapEdbConfig.layerdefs,
    layerdialog: false,
    mapEdbConfig: mapEdbConfig,
  }),
  computed: {
    ...mapState(['own_annos','foreign_annos','inc_ftrs','ev_ftrs','rr_ftrs'])
  },
  watch: {
    own_annos(newdata, olddata) {
      this.reloadSource('anno_own_src', newdata)
    },
    foreign_annos(newdata) {
      this.reloadSource('anno_foreign_src', newdata)
    },
    inc_ftrs(newdata) {
      this.reloadSource('inc_ftrs_src', newdata);
    },
    ev_ftrs(newdata) {
      this.reloadSource('ev_ftrs_src', newdata);
    },
    rr_ftrs(newdata) {
      this.reloadSource('rr_ftrs_src', newdata);
    },
  },
  mounted() {
    this.map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: "mapbox://styles/sdalakov/cltcnxc95018p01pifml74que",
      center: [-75.22, 39.55],
      zoom: 10,
    });
    this.current_map = mapEdbConfig.initialMode;
    this.map.on('load', () => {
      try {
        this.setMapMode(this.current_map);
        this.map.fitBounds(mapEdbConfig.mapInitialView, {padding:10});
        Object.keys(this.sourcedefs).forEach(k => {
          this.map.addSource(k, this.sourcedefs[k]);
        });
        this.layerdefs.forEach(lr => {
          this.map.addLayer(lr);
        })
        this.setLayerVisibility();
        this.initComplete1 = true;
        this.addMarkerIcons().then(() => {
          this.initComplete2 = true;
          this.reloadSource('inc_ftrs_src', this.inc_ftrs);
          this.reloadSource('anno_own_src', this.own_annos);
          this.reloadSource('anno_foreign_src', this.foreign_annos);
          this.reloadSource('ev_ftrs_src', this.ev_ftrs);
          this.reloadSource('rr_ftrs_src', this.rr_ftrs);
        });
      mapEdbConfig.iicepLayers.forEach(lr => {
        if (lr.type==='layer') {
          this.setLayerEvents(lr.id,lr.label);
        } else if (lr.type==='group'){
          if (lr.type === 'group') {
            lr.members.forEach(mem => {
              this.setLayerEvents(mem,lr.label);
            })
          }
        }
      })
    } catch (e) {
      console.log(e);
    }
  });
    this.mapTypeControl = new MapButton(this.toggleMapMode, './assets/street.png', '_oem_map_type');
    this.map.addControl(this.mapTypeControl, 'top-right');
    this.mapLayerControl = new MapButton(this.setMapLayers, './assets/layer.png', '_oem_layers');
    this.map.addControl(this.mapLayerControl, 'top-right');

    this.resizeObserver = new ResizeObserver(() => {
      this.map.resize();
    });
    this.resizeObserver.observe(this.$refs.mapContainer);
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    window.removeEventListener('resize', this.handleWindowResize);
    if (this.map) {
      this.map.remove();
    }
  },
  methods: {
    setLayerEvents(layername, label) {
      this.map.on('mouseenter', layername, (e) => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', layername, (e) => {
        this.map.getCanvas().style.cursor = '';
      });
      this.map.on('click',layername, (e) => {
        console.log('Object: ',JSON.stringify(e.features[0].properties));
        const coordinates = e.lngLat;
        let html = `<h3>${label}</h3><table>`;
        Object.keys(mapEdbConfig[layername].attrib).forEach(k => {
          html = html + `<tr><td><strong>${mapEdbConfig[layername].attrib[k]}</strong></td><td>${e.features[0].properties[k]}</td></tr>`;
        })
        html += '</table>';
        const iPopup=new mapboxgl.Popup({maxWidth: '500px'})
            .setLngLat(coordinates)
            .setHTML(html)
            .addTo(this.map);
      })
    },
    setMapMode: function(maptype) {
      Object.keys(mapEdbConfig.modeLayersList).forEach( modekey => {
        mapEdbConfig.modeLayersList[modekey].forEach(lr => {
          this.map.setLayoutProperty(lr, 'visibility', (modekey === maptype) ? 'visible':'none' )
        })
      })
      this.mapTypeControl.setIcon(this.btn_icons[maptype]);
    },
    toggleMapMode: function() {
      if (this.current_map==='satellite') {
        this.setMapMode('street');
        this.current_map= 'street';
      } else if (this.current_map==='street') {
        this.setMapMode('satellite');
        this.current_map= 'satellite';
      }
    },
    setMapLayers: function() {
      this.layerdialog=true;
    },
    applyLayerChanges: function() {
      this.layerdialog=false;
      this.setLayerVisibility();
    },
    handleWindowResize() {
      this.map.resize();
    },
    reloadSource(src, ftr_list) {
      if (this.map && this.map.getSource(src)) {
        let geojson;
        if (ftr_list && ftr_list.type === 'FeatureCollection' && Array.isArray(ftr_list.features)) {
          geojson = ftr_list;
        } else {
          geojson = {
            'type': 'FeatureCollection',
            'features': ftr_list || []
          };
        }
        this.map.getSource(src).setData(geojson);
      }
    },
    setLayerVisibility: function() {
      this.mapEdbConfig.iicepLayers.forEach(lr => {
        let lstate = lr.state ? 'visible' : 'none';
        if (lr.type === 'layer') {
          if (this.map.getLayer(lr.id)) {
            this.map.setLayoutProperty(lr.id, 'visibility', lstate)
          }
        }
        if (lr.type === 'group') {
          lr.members.forEach(mem => {
            if (this.map.getLayer(mem)) {
              this.map.setLayoutProperty(mem, 'visibility', lstate);
            }
          })
        }
      });
    },
    addMarkerIcons() {
      const combinedArray = [...mapEdbConfig.annoIcons, ...mapEdbConfig.incidentIcons, ...mapEdbConfig.eventIcons];
      let self = this;
      const imagePromises = combinedArray.map(irec => {
        return new Promise((resolve, reject) => {
          self.map.loadImage(`./assets/${irec.icon}`, (error, image) => {
            if (error) {
              reject(error);
            } else {
              const imgname = 'im' + irec.value;
              if (!self.map.hasImage(imgname)) {
                self.map.addImage(imgname, image);
              }
              resolve();
            }
          });
        });
      });
      return Promise.all(imagePromises);
    }
  },
}
</script>

<template>
  <div ref="mapContainer" class="map-container"></div>
  <v-dialog v-model="layerdialog" max-width="300px" theme="light">
    <v-card>
      <v-card-title>Layers</v-card-title>
      <v-card-text>
        <v-row no-gutters v-for="(layer, index) in mapEdbConfig.iicepLayers" :key="index">
          <v-col cols="12">
            <v-checkbox
                density="compact"
                hide-details
                v-model="layer.state"
                :label="layer.label"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          debsity="condensed"
          @click="applyLayerChanges"
          outlined
          >APPLY</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.map-container {
  flex: 1;
}
.custom-control-map div {
  padding-top: 5px;
}
.custom-control-map .row-map {
  display: flex;
  align-items: center; /* Vertically aligns items */
  justify-content: space-between; /* Distributes items evenly */
  margin-bottom: 5px;
}
.custom-control-map .row-map span {
  flex: 1; /* Allows the span to use remaining space */
  text-align: center; /* Centers the text */
}
.custom-control-map .row-map button {
  margin-left: 5px;
  margin-right: 5px;
}
.icon-button-map {
  width: 29px;
  height: 29px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icon-button-map svg {
  width: auto; /* Ensures the SVG scales within the padding */
  height: auto;
  display: block;
  margin: auto;
}
.hidediv {
  display: none !important;
}
#point-block,#line-block,#polygon-block {
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  align-items: start;
}
.row-map-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row-map-row button {
  height: unset;
  width: unset;
}
</style>