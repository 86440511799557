<script>
import { fb_allHazSitreps, fb_allHazEditors } from '@/firebase/init.js';
import {query, where, getDocs, addDoc, setDoc} from 'firebase/firestore';
export default {
  name: "allHazardsSitrep",
  props:{
    'eventid' : Number,
    'hazeventname': String,
  },
  data: ()=> ({
    formSections : [
      {
        sectId: 1,
        sectName : 'Event Impact/Prognosis/Classification/Wind Direction/Speed',
        sectTime : '',
        sectMsg : '',
        formType : 1,
        lastUpdate : 0,
        fields :[
          {
            id:1,
            label:'Prognosis',
            value:'',
            cols:4,
            type:'text'
          },
          {
            id:2,
            label:'Impact',
            value:'',
            cols:4,
            type:'text'
          },
          {
            id:3,
            label:'Classification',
            value:'',
            cols:4,
            type:'text'
          },
          {
            id:4,
            label:'Wind Direction',
            value:'',
            cols:4,
            type:'text'
          },
          {
            id:5,
            label:'Wind Speed',
            value:'',
            cols:4,
            type:'text'
          }
        ]
      },
      {
        sectId: 2,
        sectName : 'EOC Activations and State of Emergency Declarations',
        sectMsg : '',
        sectTime :'',
        formType : 1,
        fields:[
          {
            id:1,
            descr1: 'Salem County ',
            descr2: 'EOC Activated',
            label:'EOC Activated',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:2,
            descr1: '',
            descr2: 'County State of Emergency Declared',
            label:'Emergency Declared',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:3,
            descr1: 'Lower Alloways Creek Township ',
            descr2: 'EOC Activated',
            label:'EOC Activated',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:4,
            descr1: '',
            descr2: 'Municipal State of Emergency Declared',
            label:'Emergency Declared',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:5,
            descr1: 'Quinton Township ',
            descr2: 'EOC Activated',
            label:'EOC Activated',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:6,
            descr1: '',
            descr2: 'Municipal State of Emergency Declared',
            label:'Emergency Declared',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:7,
            descr1: 'Mannington Township ',
            descr2: 'EOC Activated',
            label:'EOC Activated',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:8,
            descr1: '',
            descr2: 'Municipal State of Emergency Declared',
            label:'Emergency Declared',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:9,
            descr1: 'Pennsville Township ',
            descr2: 'EOC Activated',
            label:'EOC Activated',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:10,
            descr1: '',
            descr2: 'Municipal State of Emergency Declared',
            label:'Emergency Declared',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:11,
            descr1: 'Elsinboro Township ',
            descr2: 'EOC Activated',
            label:'EOC Activated',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:12,
            descr1: '',
            descr2: 'Municipal State of Emergency Declared',
            label:'Emergency Declared',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:13,
            descr1: 'Salem City ',
            descr2: 'EOC Activated',
            label:'EOC Activated',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:14,
            descr1: '',
            descr2: 'Municipal State of Emergency Declared',
            label:'Emergency Declared',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:15,
            descr1: '',
            descr2: 'List all OTHERS as may be activated',
            label:'EOC Activated',
            value:'',
            cols:6,
            type:'text'
          },
          {
            id:16,
            descr1: '',
            descr2: 'Municipal State of Emergency Declared',
            label:'Emergency Declared',
            value:'',
            cols:6,
            type:'text'
          }
        ]
      },
      {
        sectId: 3,
        sectName : 'Schools Status',
        sectMsg : '',
        sectTime :'',
        formType : 2,
        fields:[
          {
            id:1,
            descr1: 'Notations',
            descr2: '',
            label:'Notations',
            value:'',
            cols:12,
            type:'textarea'
          }
        ],
        schools:[
          {
            id:1,
            name:'Alloway Township',
            closed:'',
            orderedClosed:'',
            hostSchool:'',
            relocationCompleted:'',
            notes:''
          },
          {
            id:2,
            name:'Elsinboro Township',
            closed:'',
            orderedClosed:'',
            hostSchool:'Mary Shoemaker (Woodstown)',
            relocationCompleted:'',
            notes:''
          },
          {
            id:3,
            name:'Lower Alloways Creek',
            closed:'',
            orderedClosed:'',
            hostSchool:'Schalick High School',
            relocationCompleted:'',
            notes:''
          },
          {
            id:4,
            name:'Mannington Township',
            closed:'',
            orderedClosed:'',
            hostSchool:'',
            relocationCompleted:'',
            notes:''
          },
          {
            id:5,
            name:'Oldmans Township',
            closed:'',
            orderedClosed:'',
            hostSchool:'',
            relocationCompleted:'',
            notes:''
          },
          {
            id:6,
            name:'Penns Grove-Carneys Point Reg.',
            closed:'',
            orderedClosed:'',
            hostSchool:'',
            relocationCompleted:'',
            notes:''
          },
          {
            id:7,
            name:'Pennsville Township',
            closed:'',
            orderedClosed:'',
            hostSchool:'',
            relocationCompleted:'',
            notes:''
          },
          {
            id:8,
            name:'Pittsgrove Township',
            closed:'',
            orderedClosed:'',
            hostSchool:'',
            relocationCompleted:'',
            notes:''
          },
          {
            id:9,
            name:'Quinton Township',
            closed:'',
            orderedClosed:'',
            hostSchool:'Schalick High School',
            relocationCompleted:'',
            notes:''
          },
          {
            id:10,
            name:'Salem City',
            closed:'',
            orderedClosed:'',
            hostSchool:'Penns Grove Middle School',
            relocationCompleted:'',
            notes:''
          },
          {
            id:11,
            name:'Salem County Special Services',
            closed:'',
            orderedClosed:'',
            hostSchool:'',
            relocationCompleted:'',
            notes:''
          },
          {
            id:12,
            name:'Salem County Vocational',
            closed:'',
            orderedClosed:'',
            hostSchool:'',
            relocationCompleted:'',
            notes:''
          },
          {
            id:13,
            name:'Salem City',
            closed:'',
            orderedClosed:'',
            hostSchool:'',
            relocationCompleted:'',
            notes:''
          },
          {
            id:14,
            name:'Upper Pittsgrove Township',
            closed:'',
            orderedClosed:'',
            hostSchool:'',
            relocationCompleted:'',
            notes:''
          },
          {
            id:15,
            name:'Woodstown-Pilesgrove Reg.',
            closed:'',
            orderedClosed:'',
            hostSchool:'',
            relocationCompleted:'',
            notes:''
          },
          {
            id:16,
            name:'Creative CoLaboratory Charter',
            closed:'',
            orderedClosed:'',
            hostSchool:'',
            relocationCompleted:'',
            notes:''
          },
        ]
      },
      {
        sectId: 4,
        sectName : 'Buses',
        sectMsg : 'BR Williams provides all buses for schools and transportation dependent evacuations',
        sectTime :'',
        formType: 3,
        fields:[
          {
            id:1,
            label:'Notations',
            value:'',
            cols:12,
            type:'textarea'
          },
          {
            id:2,
            label:'Staging / Standby (at Alert)',
            checkValue:false,
            cols:12,
            type:'check'
          },
          {
            id:3,
            label:'Ready at',
            checkValue:false,
            textValue:'',
            labelcols:2,
            textcols:3,
            labelColsSm:4,
            textColsSm:6,
            labelColsMd:3,
            textColsMd:4,
            cols:12,
            type:'checkWithText',
            newline:true
          },
          {
            id:4,
            label:'Buses for School relocation dispatched at',
            checkValue:false,
            textValue:'',
            labelcols:5,
            textcols:2,
            labelColsSm:6,
            textColsSm:6,
            labelColsMd:4,
            textColsMd:4,
            cols:12,
            type:'checkWithText',
            newline:true
          },
          {
            id:5,
            label:'Buses for Transportation Dependent Evacuation dispatched at',
            checkValue:false,
            textValue:'',
            labelcols:6,
            textcols:2,
            labelColsSm:6,
            textColsSm:6,
            labelColsMd:7,
            textColsMd:3,
            cols:12,
            type:'checkWithText',
            newline:true
          },
          {
            id:6,
            label:'Noted Gaps or Resource Needs',
            checkValue:false,
            textValue:'',
            labelcols:3,
            textcols:9,
            labelColsSm:6,
            textColsSm:6,
            labelColsMd:4,
            textColsMd:8,
            cols:12,
            type:'checkWithText',
            newline:true
          }
        ]
      },
      {
        sectId: 5,
        sectName : 'Evacuation Routes',
        sectMsg : '',
        sectTime :'',
        formType: 3,
        fields:[
          {
            id:1,
            label:'No Impediments reported',
            checkValue:false,
            cols:12,
            type:'check'
          },
          {
            id:2,
            label:'Impediments',
            checkValue:false,
            textValue:'',
            labelcols:2,
            textcols:10,
            labelColsSm:3,
            textColsSm:9,
            labelColsMd:3,
            textColsMd:9,
            cols:12,
            type:'checkWithText',
            newline:true
          },
          {
            id:3,
            label:'Noted Gaps or Resource Needs',
            checkValue:false,
            textValue:'',
            labelcols:3,
            textcols:9,
            labelColsSm:6,
            textColsSm:6,
            labelColsMd:4,
            textColsMd:8,
            cols:12,
            type:'checkWithText',
            newline:true
          }
        ]
      },
      {
        sectId: 6,
        sectName : 'Mass Care / Shelter Facilities',
        sectMsg : '',
        sectTime :'',
        formType: 3,
        fields:[
          {
            id:1,
            descr1: 'Salem County Community College / Other Facility:',
            descr2: '',
            label:'',
            value:'',
            cols:12,
            type:'text'
          },
          {
            id:2,
            label:'Staging (initiated at Alert)',
            checkValue:false,
            cols:12,
            type:'check'
          },
          {
            id:3,
            label:'Activating (initiated at the SAE) ',
            checkValue:false,
            cols:12,
            type:'check'
          },
          {
            id:4,
            label:'Open/Ready at',
            checkValue:false,
            textValue:'',
            labelcols:2,
            textcols:4,
            labelColsSm:6,
            textColsSm:4,
            labelColsMd:3,
            textColsMd:4,
            cols:12,
            type:'checkWithText',
            newline:true
          },
          {
            id:5,
            label:'Noted Gaps or Resource Needs',
            checkValue:false,
            textValue:'',
            labelcols:3,
            textcols:9,
            labelColsSm:6,
            textColsSm:6,
            labelColsMd:4,
            textColsMd:8,
            cols:12,
            type:'checkWithText',
            newline:true
          }
        ]
      },
      {
        sectId: 7,
        sectName : 'Reception Center',
        sectMsg : '',
        sectTime :'',
        formType: 3,
        fields:[
          {
            id:1,
            descr1: 'Salem County Vo-Tech / Other Facility:',
            descr2: '',
            label:'',
            value:'',
            cols:12,
            type:'text'
          },
          {
            id:2,
            label:'Staging (initiated at Alert)',
            checkValue:false,
            cols:12,
            type:'check'
          },
          {
            id:3,
            label:'Activating (initiated at the SAE) ',
            checkValue:false,
            cols:12,
            type:'check'
          },
          {
            id:4,
            label:'Open/Ready at',
            checkValue:false,
            textValue:'',
            labelcols:2,
            textcols:4,
            labelColsSm:3,
            textColsSm:8,
            labelColsMd:3,
            textColsMd:6,
            cols:12,
            type:'checkWithText',
            newline:true
          },
          {
            id:5,
            label:'Noted Gaps or Resource Needs',
            checkValue:false,
            textValue:'',
            labelcols:3,
            textcols:9,
            labelColsSm:6,
            textColsSm:6,
            labelColsMd:4,
            textColsMd:8,
            cols:12,
            type:'checkWithText',
            newline:true
          }
        ]
      },
      {
        sectId: 8,
        sectName : 'Emergency Worker Decontamination Center',
        sectMsg : '',
        sectTime :'',
        formType: 3,
        fields:[
          {
            id:1,
            descr1: 'Salem County Vo-Tech / Other Facility:',
            descr2: '',
            label:'',
            value:'',
            cols:12,
            type:'text'
          },
          {
            id:2,
            label:'Staging (initiated at Alert)',
            checkValue:false,
            cols:12,
            type:'check'
          },
          {
            id:3,
            label:'Activating (initiated at the SAE) ',
            checkValue:false,
            cols:12,
            type:'check'
          },
          {
            id:4,
            label:'Open/Ready at',
            checkValue:false,
            textValue:'',
            labelcols:2,
            textcols:4,
            labelColsSm:3,
            textColsSm:8,
            labelColsMd:3,
            textColsMd:6,
            cols:12,
            type:'checkWithText',
            newline:true
          },
          {
            id:5,
            label:'Noted Gaps or Resource Needs',
            checkValue:false,
            textValue:'',
            labelcols:3,
            textcols:9,
            labelColsSm:6,
            textColsSm:6,
            labelColsMd:4,
            textColsMd:8,
            cols:12,
            type:'checkWithText',
            newline:true
          }
        ]
      },
      {
        sectId: 9,
        sectName : 'Sirens / Route Alerting',
        sectMsg : '',
        sectTime :'',
        formType: 3,
        fields:[
          {
            id:1,
            label:'Siren Failures (note specific sirens)',
            checkValue:false,
            textValue:'',
            labelcols:2,
            textcols:10,
            labelColsSm:3,
            textColsSm:9,
            labelColsMd:3,
            textColsMd:9,
            cols:12,
            type:'checkWithText',
            newline:true
          },
          {
            id:2,
            label:'Initiation of Route Alerting',
            checkValue:false,
            textValue:'',
            labelcols:3,
            textcols:4,
            labelColsSm:5,
            textColsSm:5,
            labelColsMd:4,
            textColsMd:4,
            cols:12,
            type:'checkWithText',
            newline:true
          },
          {
            id:3,
            label:'Completion of Route Alerting',
            checkValue:false,
            textValue:'',
            labelcols:2,
            textcols:4,
            labelColsSm:3,
            textColsSm:8,
            labelColsMd:3,
            textColsMd:6,
            cols:12,
            type:'checkWithText',
            newline:true
          },
          {
            id:4,
            label:'Noted Gaps or Resource Needs',
            checkValue:false,
            textValue:'',
            labelcols:3,
            textcols:9,
            labelColsSm:6,
            textColsSm:6,
            labelColsMd:4,
            textColsMd:8,
            cols:12,
            type:'checkWithText',
            newline:true
          }
        ]
      },
      {
        sectId: 10,
        sectName : 'Traffic Control Points (TCP’s)',
        sectMsg : 'All points are manned by Municipal Personnel and/or NJSP, however the status here reflects only the status of Municipal Personnel',
        sectTime :'',
        formType: 4,
        fields:[
          {
            id:1,
            label:'TCP Standby at Post(s)',
            checkValue:false,
            textValue:'',
            type:'checkWithText',
          },
          {
            id:2,
            label:'TCP on Post / Ready',
            checkValue:false,
            type:'checkWithText',
          },
          {
            id:3,
            label:'ACP Standby at Post(s)',
            checkValue:false,
            textValue:'',
            type:'checkWithText',
          },
          {
            id:4,
            label:'ACP on Post / Ready',
            checkValue:false,
            type:'checkWithText',
          },
          {
            id:5,
            label:'Noted Gaps or Resource Needs',
            checkValue:false,
            textValue:'',
            labelcols:3,
            textcols:9,
            labelColsSm:6,
            textColsSm:6,
            labelColsMd:4,
            textColsMd:8,
            cols:12,
            type:'checkWithText',
            newline:true
          }
        ]
      },
      {
        sectId: 11,
        sectName : 'Disabled and Access Functional Needs (DAFN)',
        sectMsg : '',
        sectTime :'',
        formType:5,
        subsections:[
          {
            subId:1,
            subTitle:'A: Evacuation of Ambulatory and Non-Ambulatory DAFN',
            subMsg:'',
            fields:[
              {
                id:1,
                label:'Resources Staged and Ready',
                checkValue:false,
                cols:12,
                type:'check'
              },
              {
                id:2,
                label:'Evacuation Ordered at',
                checkValue:false,
                textValue:'',
                labelcols:2,
                textcols:10,
                labelColsSm:3,
                textColsSm:9,
                labelColsMd:3,
                textColsMd:9,
                cols:12,
                type:'checkWithText',
                newline:true
              },
              {
                id:3,
                label:'Initiated',
                checkValue:false,
                textValue:'',
                labelcols:2,
                textcols:5,
                labelColsSm:4,
                textColsSm:5,
                labelColsMd:3,
                textColsMd:4,
                cols:12,
                type:'checkWithText',
                newline:true
              },
              {
                id:4,
                label:'Completed',
                checkValue:false,
                textValue:'',
                labelcols:3,
                textcols:4,
                labelColsSm:4,
                textColsSm:5,
                labelColsMd:3,
                textColsMd:4,
                cols:12,
                type:'checkWithText',
                newline:true
              },
              {
                id:5,
                label:'Noted Gaps or Resource Needs',
                checkValue:false,
                textValue:'',
                labelcols:3,
                textcols:3,
                labelColsSm:4,
                textColsSm:5,
                labelColsMd:3,
                textColsMd:4,
                cols:12,
                type:'checkWithText',
                newline:true
              }
            ]
          },
          {
            subId:2,
            subTitle:'B: Special Care Facility',
            subMsg:'facility is responsible for relocating their patients when DAFN evacuation is ordered',
            fields:[
              {
                id:1,
                descr1: 'EPZ – Lindsay Place (assisted living facility) / Other Facility:',
                descr2: '',
                label:'',
                value:'',
                cols:12,
                type:'text'
              },
              {
                id:2,
                label:'Closed and patients relocated',
                checkValue:false,
                cols:12,
                type:'check'
              },
              {
                id:3,
                label:'Noted Gaps or Resource Needs',
                checkValue:false,
                textValue:'',
                labelcols:3,
                textcols:9,
                labelColsSm:6,
                textColsSm:6,
                labelColsMd:4,
                textColsMd:8,
                cols:12,
                type:'checkWithText',
                newline:true
              },
              {
                id:4,
                descr1: 'Non-EPZ – ',
                descr2: '',
                label:'',
                value:'',
                cols:12,
                type:'text'
              },
              {
                id:5,
                label:'Closed and patients relocated',
                checkValue:false,
                cols:12,
                type:'check'
              },
              {
                id:6,
                label:'Noted Gaps or Resource Needs',
                checkValue:false,
                textValue:'',
                labelcols:3,
                textcols:9,
                labelColsSm:6,
                textColsSm:6,
                labelColsMd:4,
                textColsMd:8,
                cols:12,
                type:'checkWithText',
                newline:true
              }
            ]
          },
          {
            subId:3,
            subTitle:'C: Hearing Impaired Notification',
            subMsg:'Implemented by the Municipalities at each siren sounding',
            fields:[
              {
                id:1,
                label:'Resources Staged and Ready',
                checkValue:false,
                cols:12,
                type:'check'
              },
              {
                id:2,
                label:'Initiated',
                checkValue:false,
                cols:12,
                type:'check'
              },
              {
                id:3,
                label:'Completed',
                checkValue:false,
                textValue:'',
                labelcols:3,
                textcols:9,
                labelColsSm:6,
                textColsSm:6,
                labelColsMd:4,
                textColsMd:8,
                cols:12,
                type:'checkWithText',
                newline:true
              },
              {
                id:4,
                label:'Noted Gaps or Resource Needs',
                checkValue:false,
                textValue:'',
                labelcols:3,
                textcols:9,
                labelColsSm:6,
                textColsSm:6,
                labelColsMd:4,
                textColsMd:8,
                cols:12,
                type:'checkWithText',
                newline:true
              }
            ]
          }
        ]
      },
      {
        sectId: 12,
        sectName : 'Transit Dependent Transportation',
        sectMsg : 'Implemented by County; Transportation to Reception Center; Routes are each run twice',
        sectTime :'',
        formType: 3,
        fields:[
          {
            id:1,
            label:'Resorces Staged and Ready',
            checkValue:false,
            cols:12,
            type:'check'
          },
          {
            id:2,
            label:'Initiated',
            checkValue:false,
            labelcols:2,
            textcols:4,
            labelColsSm:4,
            textColsSm:5,
            labelColsMd:3,
            textColsMd:4,
            cols:12,
            type:'checkWithText',
            newline:true
          },
          {
            id:3,
            label:'Completed',
            checkValue:false,
            textValue:'',
            labelcols:2,
            textcols:4,
            labelColsSm:4,
            textColsSm:5,
            labelColsMd:3,
            textColsMd:4,
            cols:12,
            type:'checkWithText',
            newline:true
          },
          {
            id:4,
            label:'Noted Gaps or Resource Needs',
            checkValue:false,
            textValue:'',
            labelcols:3,
            textcols:9,
            labelColsSm:6,
            textColsSm:6,
            labelColsMd:4,
            textColsMd:8,
            cols:12,
            type:'checkWithText',
            newline:true
          }
        ]
      },
      {
        sectId: 13,
        sectName : 'Stored Feed',
        sectMsg : 'Protective action for stored feed will be made by the State and transmitted to the Counties, who will notify farmers through the County Ag Agent(s)',
        sectTime :'',
        formType: 3,
        fields:[
          {
            id:1,
            label:'Notifications made by County Ag Agent',
            checkValue:false,
            cols:12,
            type:'check'
          }
        ]
      },
      {
        sectId: 14,
        sectName : 'Critical Infrastructure',
        sectMsg : '',
        sectTime :'',
        dataHdr1:'Name of Critical Infrastructure',
        dataHdr2:'Issues',
        formType: 6,
        fields:[
          {
            id:1,
            label:'No Impacts',
            checkValue:false,
            cols:12,
            type:'check'
          },
          {
            id:2,
            label:'Impacted',
            checkValue:false,
            data:[['','']],
            cols:12,
            type:'checkWithArray'
          }
        ]
      },
      {
        sectId: 15,
        sectName : 'Emergency Services',
        sectMsg : '',
        sectTime :'',
        dataHdr1:'Agency',
        dataHdr2:'Issues',
        formType: 6,
        fields:[
          {
            id:1,
            label:'No Impacts',
            checkValue:false,
            cols:12,
            type:'check'
          },
          {
            id:2,
            label:'Impacted',
            checkValue:false,
            data:[['','']],
            cols:12,
            type:'checkWithArray'
          }
        ]
      }
    ],
    formfields: {
      eventDeclared:'',
      eventPhone:'',
      eventSentBy:'',
      eventEmail:'',
      eventName:'',
      eventType:''
    },
    canSave:false,
    countyId:34033,
    docref:null,
    documents:[],
    cu:'',
    anythingChanged:false,
    ahdialog:false
  }),
  methods: {
    async loadSitrep () {
      const q1=query(
          fb_allHazEditors,
          where('county','==',this.countyId),
          where('edList','array-contains',this.cu)
      );
      const qSnap = await getDocs(q1);
      this.canSave = (qSnap.docs.length>0);
      const q=query(fb_allHazSitreps, where('event','==',this.eventid));
      const querySnapshot = await getDocs(q);
      let docdata={};
      querySnapshot.forEach((doc) => {
        docdata = doc.data();
        this.docref=doc.ref;
      });
      if (docdata.formFields) {
        Object.keys(docdata.formFields).forEach((key)=>{
          this.formfields[key]=docdata.formFields[key]
        });
      } else {
        this.formfields.eventName=this.hazeventname;
      }
      if (docdata.sectionData) {
        docdata.sectionData.forEach((elem,idx)=>{
          let sect=JSON.parse(elem);
          let sectType=this.formSections[idx].formType;
          this.formSections[idx].sectTime=sect.timestamp;
          this.formSections[idx].lastUpdate=sect.lastUpdate;
          this.formSections[idx].newUpdate=0;
          switch (sectType) {
            case 1 :
              if (sect.fields) {
                for (let i = 0; i < sect.fields.length; i++) {
                  this.formSections[idx].fields[i].value = sect.fields[i];
                }
              }
              break;
            case 2 :
              if (sect.fields) {
                for (let i = 0; i < sect.fields.length; i++) {
                  this.formSections[idx].fields[i].value = sect.fields[i];
                }
              }
              if (sect.schools) {
                for (let i = 0; i < sect.schools.length; i++) {
                  this.formSections[idx].schools[i].closed = sect.schools[i].closed;
                  this.formSections[idx].schools[i].orderedClosed = sect.schools[i].orderedClosed;
                  this.formSections[idx].schools[i].hostSchool = sect.schools[i].hostSchool;
                  this.formSections[idx].schools[i].relocationCompleted = sect.schools[i].relocationCompleted;
                  this.formSections[idx].schools[i].notes = sect.schools[i].notes;
                }
              }
              break;
            case 3 :
            case 4 :
              if (sect.fields) {
                for (let i = 0; i < sect.fields.length; i++) {
                  switch (this.formSections[idx].fields[i].type) {
                    case 'textarea' :
                    case 'text' :
                      this.formSections[idx].fields[i].value = sect.fields[i];
                      break;
                    case 'check' :
                      this.formSections[idx].fields[i].checkValue = sect.fields[i];
                      break;
                    case 'checkWithText' :
                      this.formSections[idx].fields[i].checkValue = (typeof(sect.fields[i][0])==='undefined'?false:sect.fields[i][0]);
                      this.formSections[idx].fields[i].textValue = (typeof(sect.fields[i][1])==='undefined'?false:sect.fields[i][1]);
                      break;
                  }
                }
              }
              break;
            case 5:
              if (sect.fields) {
                for (let i = 0; i < sect.fields.length; i++) {
                  for (let k = 0; k < sect.fields[i].length; k++) {
                    switch (this.formSections[idx].subsections[i].fields[k].type) {
                      case 'textarea' :
                      case 'text' :
                        this.formSections[idx].subsections[i].fields[k].value = sect.fields[i][k];
                        break;
                      case 'check' :
                        this.formSections[idx].subsections[i].fields[k].checkValue = sect.fields[i][k];
                        break;
                      case 'checkWithText' :
                        this.formSections[idx].subsections[i].fields[k].checkValue = (typeof (sect.fields[i][k][0]) === 'undefined' ? '' : sect.fields[i][k][0]);
                        this.formSections[idx].subsections[i].fields[k].textValue = (typeof (sect.fields[i][k][1]) === 'undefined' ? '' : sect.fields[i][k][1]);
                        break;
                    }
                  }
                }
              }
              break;
            case 6:
              if (sect.fields) {
                for (let i = 0; i < sect.fields.length; i++) {
                  switch (this.formSections[idx].fields[i].type) {
                    case 'textarea' :
                    case 'text' :
                      this.formSections[idx].fields[i].value = sect.fields[i];
                      break;
                    case 'check' :
                      this.formSections[idx].fields[i].checkValue = sect.fields[i];
                      break;
                    case 'checkWithText' :
                      this.formSections[idx].fields[i].checkValue = (typeof (sect.fields[i][0]) === 'undefined' ? '' : sect.fields[i][0]);
                      this.formSections[idx].fields[i].textValue = (typeof (sect.fields[i][1]) === 'undefined' ? '' : sect.fields[i][1]);
                      break;
                    case 'checkWithArray' :
                      this.formSections[idx].fields[i].checkValue = (typeof (sect.fields[i][0]) === 'undefined' ? '' : sect.fields[i][0]);
                      this.formSections[idx].fields[i].data = (typeof (sect.fields[i][1]) === 'undefined' ? '' : sect.fields[i][1]);
                      this.formSections[idx].fields[i].data.push(['','']);
                      break;
                  }
                }
              }
              break;
          }
        });
      }
    },
    async saveSitrep() {
      let saveData={
        formFields:{},
        sectionData:[],
        event:this.eventid,
        county:34033
      }
      Object.keys(this.formfields).forEach((key)=>{
        saveData.formFields[key]=this.formfields[key]
      });
      for (let i=0 ; i<this.formSections.length ; i++) {
        let fldvals;
        let schvals;
        let sectdata;
        let data;
        switch (this.formSections[i].formType) {
          case 1:
            fldvals=[];
            for (let j=0 ; j<this.formSections[i].fields.length ; j++) {
              fldvals.push(this.formSections[i].fields[j].value);
            }
            sectdata={timestamp:this.formSections[i].sectTime,fields:fldvals};
            break ;
          case 2:
            fldvals=[];
            schvals=[];
            for (let j=0 ; j<this.formSections[i].fields.length ; j++) {
              fldvals.push(this.formSections[i].fields[j].value);
            }
            for (let j=0 ; j<this.formSections[i].schools.length ; j++) {
              schvals.push ({
                closed:this.formSections[i].schools[j].closed,
                orderedClosed:this.formSections[i].schools[j].orderedClosed,
                hostSchool:this.formSections[i].schools[j].hostSchool,
                relocationCompleted:this.formSections[i].schools[j].relocationCompleted,
                notes:this.formSections[i].schools[j].notes
              });
            }
            sectdata={timestamp:this.formSections[i].sectTime,fields:fldvals,schools:schvals};
            break;
          case 3:
          case 4:
            fldvals=[];
            for (let j=0 ; j<this.formSections[i].fields.length ; j++) {
              switch (this.formSections[i].fields[j].type) {
                case 'textarea' :
                case 'text' :
                  fldvals.push(this.formSections[i].fields[j].value);
                  break;
                case 'check' :
                  fldvals.push(this.formSections[i].fields[j].checkValue);
                  break;
                case 'checkWithText' :
                  fldvals.push([this.formSections[i].fields[j].checkValue,this.formSections[i].fields[j].textValue]);
                  break;
              }
            }
            sectdata={timestamp:this.formSections[i].sectTime,fields:fldvals};
            break;
          case 5:
            fldvals=[];
            for (let k=0 ; k<this.formSections[i].subsections.length ; k++) {
              fldvals[k]=[];
              for (let j = 0; j < this.formSections[i].subsections[k].fields.length; j++) {
                switch (this.formSections[i].subsections[k].fields[j].type) {
                  case 'textarea' :
                  case 'text' :
                    fldvals[k].push(this.formSections[i].subsections[k].fields[j].value);
                    break;
                  case 'check' :
                    fldvals[k].push(this.formSections[i].subsections[k].fields[j].checkValue);
                    break;
                  case 'checkWithText' :
                    fldvals[k].push([this.formSections[i].subsections[k].fields[j].checkValue, this.formSections[i].subsections[k].fields[j].textValue]);
                    break;
                }
              }
            }
            sectdata={timestamp:this.formSections[i].sectTime,fields:fldvals};
            break;
          case 6:
            fldvals=[];
            for (let j=0 ; j<this.formSections[i].fields.length ; j++) {
              switch (this.formSections[i].fields[j].type) {
                case 'textarea' :
                case 'text' :
                  fldvals.push(this.formSections[i].fields[j].value);
                  break;
                case 'check' :
                  fldvals.push(this.formSections[i].fields[j].checkValue);
                  break;
                case 'checkWithText' :
                  fldvals.push([this.formSections[i].fields[j].checkValue,this.formSections[i].fields[j].textValue]);
                  break;
                case 'checkWithArray' :
                  data=[];
                  for (let k=0; k<this.formSections[i].fields[j].data.length; k++) {
                    let drow=this.formSections[i].fields[j].data[k];
                    if (drow[0]!=='' || drow[1]!=='') {
                      data.push(drow);
                    }
                  }
                  fldvals.push([this.formSections[i].fields[j].checkValue,data]);
                  break;
              }
            }
            sectdata={timestamp:this.formSections[i].sectTime,fields:fldvals};
            break;
          default :
            sectdata={timestamp:this.formSections[i].sectTime,fields:[],schools:[]};
        }
        if (this.anythingChanged) sectdata.lastUpdate=this.formSections[i].newUpdate;
        else sectdata.lastUpdate=this.formSections[i].lastUpdate;
        saveData.sectionData.push(JSON.stringify(sectdata));
      }
      if (this.docref) {
        await setDoc(this.docref,saveData);
      } else {
        await addDoc(fb_allHazSitreps,saveData);
      }
    },
    ts2str(timestamp) {
      let d=new Date();
      if (timestamp==='' || isNaN(timestamp)) {
        return 'N/A';
      } else {
        d.setTime(timestamp);
        return d.toLocaleString('en-US');
      }
    },
    addRow(sectId){
      let self=this;
      self.formSections[sectId-1].sectTime=Date.now();
      self.formSections[sectId-1].newUpdate=1;
      self.anythingChanged=true;
      let data=self.formSections[sectId-1].fields[1].data;
      let last=data.length-1;
      if (data[last][0]!=='' || data[last][1]!=='') {
        data.push(['','']);
      }
    },
    setEditTime(sectId){
      let self=this;
      self.formSections[sectId-1].sectTime=Date.now();
      self.formSections[sectId-1].newUpdate=1;
      self.anythingChanged=true;
    },
    closeDlg() {
      this.ahdialog=false;
    },
    async saveForm() {
      await self.saveSitrep();
      this.ahdialog=false;
    },
    initSitrep() {
      this.cu = this.$store.state.loggedUserObj.MEMBER_MAIL;
      this.loadSitrep()
    }
  }
}

</script>

<template>
  <v-dialog
      v-model="ahdialog"
      persistent
      theme="light"
      width="90%"
  >
  <template v-slot:activator="{ props }">
    <v-btn
        density="default"
        v-bind="props"
        variant="outlined"
        size="small"
        @click="initSitrep"
    > All-Hazards SitRep
    </v-btn>
  </template>
    <v-card class="ma-0 pa-0 fwidth" >
      <v-card-text class="fill-height">
          <v-row align="center" justify="center">
            <v-col cols="12" lg="10">
              <v-card class="elevation-12">
                <v-toolbar color="primary" dark flat class="text-center">
                  <v-spacer></v-spacer>
                  <v-toolbar-title>
                    Salem County All Hazards Sitrep
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-form>
                    <v-row>
                      <v-col cols="12" sm="6" md="4" lg="2" class="px-1">
                        <v-text-field
                            placeholder="Event Name"
                            persistent-hint
                            hint="Event Name"
                            v-model="formfields.eventName"
                            autocomplete='new-password'
                            readonly
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="2" class="px-1">
                        <v-text-field
                            placeholder="Type Of Event"
                            persistent-hint
                            hint="Type of Event"
                            v-model="formfields.eventType"
                            autocomplete='new-password'
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="2" class="px-1">
                        <v-text-field
                            placeholder="Declared"
                            persistent-hint
                            hint="Declared"
                            v-model="formfields.eventDeclared"
                            autocomplete='new-password'
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="2" class="px-1">
                        <v-text-field
                            placeholder="Sent By"
                            persistent-hint
                            hint="Sent By"
                            v-model="formfields.eventSentBy"
                            autocomplete='new-password'
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="2" class="px-1">
                        <v-text-field
                            placeholder="Phone"
                            persistent-hint
                            hint="Phone"
                            v-model="formfields.eventPhone"
                            autocomplete='new-password'
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="2" class="px-1">
                        <v-text-field
                            placeholder="Email"
                            persistent-hint
                            hint="Email"
                            v-model="formfields.eventEmail"
                            autocomplete='new-password'
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <span class="text-h5 text-md-h4">Information Updates & Revisions</span>
                      </v-col>
                    </v-row>
                    <v-row v-for="(fld) in formSections" :key="fld.sectId">
                      <v-col cols="12" sm="7" md="9" lg="9" class="text-left align-self-start text-subtitle-1">
                        <a :href="'#section'+fld.sectId.toString()">
                          <span>SECTION {{fld.sectId}}:</span>
                          <span class="px-1">{{fld.sectName}}</span>
                        </a>
                      </v-col>
                      <v-col cols="12" sm="5" md="3" lg="3" class="text-right align-self-start text-subtitle-1">
                        <span class="px-1">Updated:</span>
                        <span
                            :class="fld.lastUpdate ? 'font-weight-bold red--text':'font-weight-bold black--text'"
                        >{{ts2str(fld.sectTime)}}
                          </span>
                      </v-col>
                    </v-row>
                    <template v-for="(sect) in formSections" :key="sect.sectId">
                      <v-row >
                        <v-col cols="12" class="text-h5 text-left indigo--text" :id="'section'+sect.sectId.toString()">
                          <span class="text-decoration-underline">Section {{sect.sectId}}: </span>&nbsp;
                          {{sect.sectName}}
                          <span class="text-body-2" v-if="sect.sectMsg!==''">({{sect.sectMsg}})</span>
                        </v-col>
                      </v-row>
                      <template v-if="sect.formType===1">
                        <v-row class="px-4 mx-2" >
                          <v-col
                              v-for="(fld) in sect.fields"
                              :key="sect.sectId*1000+fld.id*20+1"
                              :cols="fld.cols"
                              class="d-flex align-center justify-start"
                          >
                            <div class="pt-3 px-1 text-body-1">
                              <span class="font-weight-bold">{{fld.descr1}}</span>
                              <span> {{fld.descr2}}</span>
                            </div>
                            <v-text-field
                                :placeholder="fld.label"
                                persistent-hint
                                :hint="fld.label"
                                v-model="fld.value"
                                @change="setEditTime(sect.sectId)"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-if="sect.formType===2">
                        <v-row class="px-4 mx-2" >
                          <v-col
                              cols="12"
                              class="d-flex align-start justify-start"
                          >
                            <div class="pt-3 px-1 text-body-1">
                              <span class="font-weight-bold">{{sect.fields[0].descr1}}</span>
                            </div>
                            <v-textarea
                                :placeholder="sect.fields[0].label"
                                persistent-hint
                                outlined
                                :hint="sect.fields[0].label"
                                v-model="sect.fields[0].value"
                                @change="setEditTime(sect.sectId)"
                            >
                            </v-textarea>
                          </v-col>
                        </v-row>
                        <v-row class="px-4 mx-2" >
                          <v-col cols="12">
                            <v-table>
                              <thead>
                              <tr>
                                <th class="text-body-1 font-weight-bold">School</th>
                                <th class="text-body-1 font-weight-bold">Closed (Y/N)</th>
                                <th class="text-body-1 font-weight-bold">Ordered Closed</th>
                                <th class="text-body-1 font-weight-bold">Host School</th>
                                <th class="text-body-1 font-weight-bold">Relocation Completed</th>
                                <th class="text-body-1 font-weight-bold">Notes</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="school in sect.schools" :key="school.id">
                                <td>{{school.name}}</td>
                                <td class="pt-5">
                                  <v-text-field
                                      v-model="school.closed"
                                      dense
                                      outlined
                                      @change="setEditTime(sect.sectId)"
                                  >
                                  </v-text-field>
                                </td>
                                <td class="pt-5">
                                  <v-text-field
                                      v-model="school.orderedClosed"
                                      dense
                                      outlined
                                      @change="setEditTime(sect.sectId)"
                                  >
                                  </v-text-field>
                                </td>
                                <td class="pt-5">
                                  <v-text-field
                                      v-model="school.hostSchool"
                                      dense
                                      outlined
                                      @change="setEditTime(sect.sectId)"
                                  >
                                  </v-text-field>
                                </td>
                                <td class="pt-5">
                                  <v-text-field
                                      v-model="school.relocationCompleted"
                                      dense
                                      outlined
                                      @change="setEditTime(sect.sectId)"
                                  >
                                  </v-text-field>
                                </td>
                                <td class="pt-5">
                                  <v-text-field
                                      v-model="school.notes"
                                      dense
                                      outlined
                                      @change="setEditTime(sect.sectId)"
                                  >
                                  </v-text-field>
                                </td>
                              </tr>
                              </tbody>
                            </v-table>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-if="sect.formType===3">
                        <template v-for="(fld) in sect.fields">
                          <v-row
                              class="px-4 mx-2"
                              no-gutters
                          >
                            <template v-if="fld.type==='text'">
                              <v-col
                                  :cols="fld.cols"
                                  class="d-flex align-start justify-start"
                              >
                                <div class="px-1 text-body-1 pt-2 text-left">
                                  <span class="font-weight-bold">{{fld.descr1}}</span>
                                  <span> {{fld.descr2}}</span>
                                </div>
                                <v-text-field
                                    :placeholder="fld.label"
                                    persistent-hint
                                    :hint="fld.label"
                                    v-model="fld.value"
                                    outlined
                                    dense
                                    class=""
                                    @change="setEditTime(sect.sectId)"
                                >
                                </v-text-field>
                              </v-col>
                            </template>
                            <template v-if="fld.type==='textarea'">
                              <v-col
                                  :cols="fld.cols"
                                  class="d-flex align-start justify-start"
                              >
                                <div class="pt-3 px-1 text-body-1">
                                  <span class="font-weight-bold">{{fld.label}}</span>
                                </div>
                                <v-textarea
                                    :placeholder="fld.label"
                                    persistent-hint
                                    outlined
                                    :hint="fld.label"
                                    v-model="fld.value"
                                >
                                </v-textarea>
                              </v-col>
                            </template>
                            <template v-if="fld.type==='check'">
                              <v-col
                                  :cols="fld.cols"
                                  class="d-flex align-start justify-start"
                              >
                                <v-checkbox
                                    v-model="fld.checkValue"
                                    :label="fld.label"
                                    hide-details
                                    @change="setEditTime(sect.sectId)"
                                ></v-checkbox>
                              </v-col>
                            </template>
                            <template v-if="fld.type==='checkWithText'">
                              <v-col
                                  class="d-flex align-start justify-start"
                                  :lg="fld.textcols+fld.labelcols"
                                  :md="fld.textColsMd+fld.labelColsMd"
                                  :cols="fld.textColsSm+fld.labelColsSm"
                              >
                                <v-checkbox
                                    v-model="fld.checkValue"
                                    :label="fld.label"
                                    hide-details
                                    @change="setEditTime(sect.sectId)"
                                ></v-checkbox>
                                <v-text-field
                                    v-model="fld.textValue"
                                    dense
                                    outlined
                                    class="pt-3 pl-3"
                                    @change="setEditTime(sect.sectId)"
                                ></v-text-field>
                              </v-col>
                            </template>
                          </v-row>
                        </template>
                      </template>
                      <template v-if="sect.formType===4">
                        <v-row
                            cols="12"
                            class="px-4 mx-2"
                        >
                          <v-col
                              cols="12"
                              md="6"
                              class="pr-3 pl-0"
                          >
                            <div class="light-blue--text">
                              Traffic Control Point
                            </div>
                            <div class="d-flex align-start justify-start">
                              <v-checkbox
                                  v-model="sect.fields[0].checkValue"
                                  :label="sect.fields[0].label"
                                  hide-details
                                  @change="setEditTime(sect.sectId)"
                              ></v-checkbox>
                              <v-text-field
                                  v-model="sect.fields[0].textValue"
                                  dense
                                  outlined
                                  class="pt-3 pl-3"
                                  cols="6"
                                  @change="setEditTime(sect.sectId)"
                              ></v-text-field>
                            </div>
                            <div>
                              <v-checkbox
                                  v-model="sect.fields[1].checkValue"
                                  :label="sect.fields[1].label"
                                  hide-details
                                  @change="setEditTime(sect.sectId)"
                              ></v-checkbox>
                            </div>
                          </v-col>
                          <v-col
                              cols="12"
                              md="6"
                              class="pl-3 pr-0"
                          >
                            <div class="light-blue--text">
                              Access Control Point
                            </div>
                            <div class="d-flex align-start justify-start">
                              <v-checkbox
                                  v-model="sect.fields[2].checkValue"
                                  :label="sect.fields[2].label"
                                  hide-details
                                  @change="setEditTime(sect.sectId)"
                              ></v-checkbox>
                              <v-text-field
                                  v-model="sect.fields[2].textValue"
                                  dense
                                  outlined
                                  class="pt-3 pl-3"
                                  cols="6"
                                  @change="setEditTime(sect.sectId)"
                              ></v-text-field>
                            </div>
                            <div>
                              <v-checkbox
                                  v-model="sect.fields[3].checkValue"
                                  :label="sect.fields[3].label"
                                  hide-details
                              ></v-checkbox>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row
                            cols="12"
                            class="px-4 mx-2"
                        >
                          <v-col
                              class="d-flex align-start justify-start pl-0 pr-0"
                              :lg="sect.fields[4].textcols+sect.fields[4].labelcols"
                              :md="sect.fields[4].textColsMd+sect.fields[4].labelColsMd"
                              :cols="sect.fields[4].textColsSm+sect.fields[4].labelColsSm"
                          >
                            <v-checkbox
                                v-model="sect.fields[4].checkValue"
                                :label="sect.fields[4].label"
                                hide-details
                                @change="setEditTime(sect.sectId)"
                            ></v-checkbox>
                            <v-text-field
                                v-model="sect.fields[4].textValue"
                                dense
                                outlined
                                class="pt-3 pl-3"
                                @change="setEditTime(sect.sectId)"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-if="sect.formType===5">
                        <template v-for="(subsect) in sect.subsections">
                          <v-row >
                            <v-col cols="12" class="text-h6 text-left indigo--text">
                              <span>{{subsect.subTitle}}: </span>&nbsp;
                              <span class="text-body-2" v-if="subsect.subMsg!==''">({{subsect.subMsg}})</span>
                            </v-col>
                          </v-row>
                          <template v-for="(fld) in subsect.fields">
                            <v-row
                                class="px-4 mx-2"

                                no-gutters
                            >
                              <template v-if="fld.type==='text'">
                                <v-col
                                    :cols="fld.cols"
                                    class="d-flex align-start justify-start"
                                >
                                  <div class="px-1 text-body-1 pt-2 text-left">
                                    <span class="font-weight-bold">{{fld.descr1}}</span>
                                    <span> {{fld.descr2}}</span>
                                  </div>
                                  <v-text-field
                                      :placeholder="fld.label"
                                      persistent-hint
                                      :hint="fld.label"
                                      v-model="fld.value"
                                      outlined
                                      dense
                                      class=""
                                      @change="setEditTime(sect.sectId)"
                                  >
                                  </v-text-field>
                                </v-col>
                              </template>
                              <template v-if="fld.type==='textarea'">
                                <v-col
                                    :cols="fld.cols"
                                    class="d-flex align-start justify-start"
                                >
                                  <div class="pt-3 px-1 text-body-1">
                                    <span class="font-weight-bold">{{fld.label}}</span>
                                  </div>
                                  <v-textarea
                                      :placeholder="fld.label"
                                      persistent-hint
                                      outlined
                                      :hint="fld.label"
                                      v-model="fld.value"
                                      @change="setEditTime(sect.sectId)"
                                  >
                                  </v-textarea>
                                </v-col>
                              </template>
                              <template v-if="fld.type==='check'">
                                <v-col
                                    :cols="fld.cols"
                                    class="d-flex align-start justify-start"
                                >
                                  <v-checkbox
                                      v-model="fld.checkValue"
                                      :label="fld.label"
                                      hide-details
                                      @change="setEditTime(sect.sectId)"
                                  ></v-checkbox>
                                </v-col>
                              </template>
                              <template v-if="fld.type==='checkWithText'">
                                <v-col
                                    class="d-flex align-start justify-start"
                                    :lg="fld.textcols+fld.labelcols"
                                    :md="fld.textColsMd+fld.labelColsMd"
                                    :cols="fld.textColsSm+fld.labelColsSm"
                                >
                                  <v-checkbox
                                      v-model="fld.checkValue"
                                      :label="fld.label"
                                      hide-details
                                      @change="setEditTime(sect.sectId)"
                                  ></v-checkbox>
                                  <v-text-field
                                      v-model="fld.textValue"
                                      dense
                                      outlined
                                      class="pt-3 pl-3"
                                      @change="setEditTime(sect.sectId)"
                                  ></v-text-field>
                                </v-col>
                              </template>
                            </v-row>
                          </template>
                        </template>
                      </template>
                      <template v-if="sect.formType===6">
                        <template v-for="(fld) in sect.fields">
                          <template v-if="fld.type==='check'">
                            <v-row
                                cols="12"
                                :key="sect.sectId*1000+fld.id*10"
                                class="px-4 mx-2"
                            >
                              <v-col
                                  :cols="fld.cols"
                                  class="d-flex align-start justify-start"
                              >
                                <v-checkbox
                                    v-model="fld.checkValue"
                                    :label="fld.label"
                                    hide-details
                                    @change="setEditTime(sect.sectId)"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </template>
                          <template v-if="fld.type==='checkWithArray'">
                            <v-row
                                cols="12"
                                :key="sect.sectId*1000+fld.id*10"
                                class="px-4 mx-2"
                            >
                              <v-col
                                  :cols="fld.cols"
                                  class="d-flex align-start justify-start"
                              >
                                <v-checkbox
                                    v-model="fld.checkValue"
                                    :label="fld.label"
                                    hide-details
                                    @change="setEditTime(sect.sectId)"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                            <v-row class="px-4 mx-2" :key="sect.sectId*1000+fld.id*10+1">
                              <v-col cols="12">
                                <v-table>
                                  <thead>
                                  <tr>
                                    <th class="text-body-1 font-weight-bold">{{sect.dataHdr1}}</th>
                                    <th class="text-body-1 font-weight-bold">{{sect.dataHdr2}}</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr v-for="(row,i) in fld.data" :key="sect.sectId*1000+500+i">
                                    <td class="pt-5">
                                      <v-text-field
                                          v-model="row[0]"
                                          dense
                                          outlined
                                          @change="addRow(sect.sectId)"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="pt-5">
                                      <v-text-field
                                          v-model="row[1]"
                                          dense
                                          outlined
                                          @change="addRow(sect.sectId)"
                                      >
                                      </v-text-field>
                                    </td>
                                  </tr>
                                  </tbody>
                                </v-table>
                              </v-col>
                            </v-row>
                          </template>
                        </template>
                      </template>
                    </template>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                      color="primary"
                      @click="closeDlg"
                  >Close</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      @click="saveForm"
                      v-show="canSave"
                  >UPDATE</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.fwidth {
  width: 100%;
}
</style>