export const map911Config = {
    initialMode: 'street',
    modeLayersList: {
        street: [
            'road-exit-shield', 'road-number-shield', 'road-intersection', 'road-secondary-tertiary', 'road-street-low',
            'road-street', 'road-major-link', 'road-minor-link', 'road-minor', 'road-construction', 'turning-feature',
            'road-motorway-trunk-case', 'road-major-link-case', 'road-primary-case', 'road-street-case', 'road-minor-case',
            'turning-feature-outline', 'road-secondary-tertiary-case', 'road-minor-link-case'
        ],
        satellite: [
            'satellite'
        ]
    },
    iicepLayers: [
        { 'label':'ERPA', 'id':'erpa-ll-1oikwz', 'state': false, 'type':'layer'},
        { 'label':'Dams(Salem)', 'id':'dams1-66ktwt', 'state': false, 'type':'layer'},
        { 'label':'Dams(NJ)', 'id':'dams2-bcw6vq', 'state': false, 'type':'layer'},
        { 'label':'Sirens', 'id':'sirens-salem', 'state': false, 'type':'layer'},
        { 'label':'Congregate Care', 'id':'congregate-care', 'state': false, 'type':'layer'},
        { 'label':'Bus Routes', 'id':'bus-routes', 'state': false, 'type':'group', 'members':['bus-routes-lines', 'bus-routes-arrows']}
    ],
    iconslist: [
        {value: "311", icon: "311.png", label: "Road Closure"},
        {value: "406", icon: "406.png", label: "Detour"},
        {value: "430", icon: "430.png", label: "Flood"},
        {value: "421", icon: "421.png", label: "Major Fire"},
        {value: "EMS", icon: "ems32.png", label: "EMS"},
        {value: "FIRE", icon: "fire32.png", label: "FIRE"},
        {value: "FIREEMS", icon: "fireems32.png", label: "FIREEMS"},
    ],
    drawStyles: [
        {
            'id': 'highlight-active-points',
            'type': 'symbol',
            'filter': ['all',
                ['==', '$type', 'Point'],
                ['==', 'meta', 'feature'],
                ['==', 'active', 'true']],
            'paint': {
                'icon-opacity': 1
            },
            'layout': {
                'icon-allow-overlap': true,
                'icon-anchor': 'center',
                'icon-ignore-placement': true,
                'icon-size': 1.2,
                'icon-image': ['get','user_icon']
            }
        },
        {
            'id': 'points-are-icons',
            'type': 'symbol',
            'filter': ['all',
                ['==', '$type', 'Point'],
                ['==', 'meta', 'feature'],
                ['==', 'active', 'false']],
            'paint': {
                'icon-opacity': 1
            },
            'layout': {
                'icon-allow-overlap': true,
                'icon-anchor': 'center',
                'icon-ignore-placement': true,
                'icon-image': ['get','user_icon']
            }
        },
        {
            "id": "gl-draw-line",
            "type": "line",
            "filter": ["all", ["==", "$type", "LineString"]],
            "layout": {
                "line-cap": "round",
                "line-join": "round"
            },
            "paint": {
                "line-color": ["coalesce", ["get", "user_lineColor"], "#AAAAAA"],
                "line-dasharray": [3, 2],
                "line-width": 3
            }
        },
        {
            "id": "gl-draw-line-and-line-vertex-halo",
            "type": "circle",
            "filter": ["all",
                ["==", "meta", "vertex"],
                ['==', 'active', 'false'],
                ["==", "$type", "Point"]],
            "paint": {
                "circle-radius": 5,
                "circle-color": "#D20C0C"
            }
        },
        {
            "id": "gl-draw-line-and-line-vertex",
            "type": "circle",
            "filter": ["all",
                ["==", "meta", "vertex"],
                ['==', 'active', 'false'],
                ["==", "$type", "Point"]],
            "paint": {
                "circle-radius": 3,
                "circle-color": "#FF0",
            }
        },
        {
            "id": "gl-draw-line-and-line-vertex-halo-active",
            "type": "circle",
            "filter": ["all",
                ["==", "meta", "vertex"],
                ['==', 'active', 'true'],
                ["==", "$type", "Point"]],
            "paint": {
                "circle-radius": 7,
                "circle-color": "#D20C0C"
            }
        },
        {
            "id": "gl-draw-line-and-line-vertex-active",
            "type": "circle",
            "filter": ["all",
                ["==", "meta", "vertex"],
                ['==', 'active', 'true'],
                ["==", "$type", "Point"]],
            "paint": {
                "circle-radius": 5,
                "circle-color": "#FF0",
            }
        },
        {
            'id': 'gl-draw-line-midpoint',
            'type': 'circle',
            'filter': ['all',
                ['==', '$type', 'Point'],
                ['==', 'meta', 'midpoint']],
            'paint': {
                'circle-radius': 3,
                'circle-color': '#820C0C'
            }
        },
    ],
    mapInitialView: [[-75.5596, 39.3803], [-75.061854, 39.787539]],
    inc_points: {
        attrib: {
            'idn': 'ID',
            'iaddr': 'Address',
            'iresrc': 'Resource',
            'itext': 'Text',
            'itime': 'Time',
            'itype': 'Type'
        }
    },
    layerdefs: [
        {
            id: 'inc_points',
            type: 'symbol',
            source: 'incidents911',
            paint: {
                'icon-opacity': 1
            },
            layout: {
                'icon-allow-overlap': true,
                'icon-anchor': 'center',
                'icon-ignore-placement': true,
                'icon-image': ['get','icontype'],
                'visibility': 'visible'
            }
        },
        {
            id: 'anno_points',
            type: 'symbol',
            source: 'anno_ftrs_src',
            filter: ['==', '$type', 'Point'],
            paint: {
                'icon-opacity': 1
            },
            layout: {
                'icon-allow-overlap': true,
                'icon-anchor': 'center',
                'icon-ignore-placement': true,
                'icon-image': ['get','icon'],
                'visibility': 'visible'
            }
        },
        {
            id: 'anno_lines',
            type: 'line',
            source: 'anno_ftrs_src',
            filter: ["all", ["==", "$type", "LineString"]],
            paint: {
                "line-color": ["coalesce", ["get", "lineColor"], "#AAAAAA"],
                'line-width': 4
            }
        }
    ],
    sourcedefs: {
        incidents911: {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        },
        anno_ftrs_src: {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        },
    },
}