<script>
import {geocodeAddress, objectToFormData, saveData} from "@/services.js";
import {mapMutations, mapGetters} from "vuex";
import { fb_events } from '@/firebase/init.js';
import {query, where, getDocs, addDoc, setDoc} from 'firebase/firestore';

export default {
  methods: {
    ...mapMutations(['setTempCoords']),
    goToEvents: function() {
      this.setTempCoords(null);
      this.$router.push("/oem/events")
    },
    async saveAndClose() {
      console.log(JSON.stringify(this.$store.state.tempPinCoords))
      const edata = {'name': this.ename, "type": this.etype, "address" : this.eaddress, "lat": this.$store.state.tempPinCoords['lat'],
        "lng": this.$store.state.tempPinCoords['lng'], "status": this.estatus, "afjuri": this.selectedMunis.join(','),
        "sdr": this.dworker, "pdr": this.sworker, mode:'addevent'
      };
      try {
        const data = await saveData(objectToFormData(edata));
        if (data['Status'] === 'OK') {
          this.setTempCoords(null);
          let fbevent = {
            eventId: data['evid'],
            accessKeyD: `${data['dcode']}`,
            accessKeyE: `${data['ecode']}`,
            eventName: `${edata.name}`,
            eventAddress: `${edata.address}`,
            statustxt: `${this.selectedEventText}`
          };
          await this.saveEventCollection(fbevent.eventId, fbevent);
          this.$router.push("/oem/events")
        }
      } catch (error) {
        alert('Error saving event: ' + error + '')
      }
    },
    async saveEventCollection(evId,eventdata){
      const q1=query(fb_events, where('eventId','==',evId));
      const qSnap = await getDocs(q1);
      let evref=null;
      qSnap.forEach((doc) => {
        evref=doc.ref;
      });
      if (evref) {
        await setDoc(evref,eventdata);
      } else {
        await addDoc(fb_events,eventdata);
      }
    },
    searchAddress() {
      geocodeAddress(this.eaddress)
          .then(
              data => {
                let location = [];
                if (data['Status'] === 'OK') {
                  location = data.coords;
                }
                this.setTempCoords(location);
              })
          .catch(
              error => {
                console.error(error)
              }
          );
    },
    selectionChanged: function () {
      console.log(JSON.stringify(this.selectedMunis))
    },
  },
  computed: {
    ...mapGetters(['getRecipientsA']),
    muniList() {
      return this.getRecipientsA.map(rec => ({'l': rec.Name, 'v': rec.Code}));
    },
    concatenatedSelectedItems() {
      return this.selectedMunis
          .map((v) => this.muniList.find((item) => item.v === v).l)
          .join(', ');
    },
    selectedEventText() {
      const selected = this.$eventStatus.find(item => item.v === this.estatus);
      return selected ? selected.l : null; // Return the label text
    },
  },
  data: () => ({
    eaddress: '',
    ename: '',
    etype: 0,
    estatus: 0,
    dworker: 0,
    sworker: 0,
    selectedMunis: []
  }),
}
</script>
<template>
  <v-card>
    <v-card-title class="text-center mt-3">Create New Event</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="ename"
              density="compact"
              hide-details
              label="Event name"
              required
              variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="eaddress"
              append-inner-icon="mdi-magnify"
              density="compact"
              hide-details
              label="Event address"
              variant="outlined"
              @change="searchAddress"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
        >
          <v-autocomplete
              v-model="etype"
              :items="$eventTypes"
              density="compact"
              hide-details
              item-title="l"
              item-value="v"
              label="Event type"
              variant="outlined"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
        >
          <v-autocomplete
              v-model="estatus"
              :items="$eventStatus"
              density="compact"
              hide-details
              item-title="l"
              item-value="v"
              label="Event Status"
              variant="outlined"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
        >
          <v-autocomplete
              v-model="selectedMunis"
              :items="muniList"
              density="compact"
              hide-details
              item-title="l"
              item-value="v"
              label="Affected Jurisdiction"
              multiple
              variant="outlined"
              @update:model-value="selectionChanged"
          >
            <template v-slot:selection="{ item, index }">
              <div v-if="index===0" class="custom-selection">
                {{ concatenatedSelectedItems }}
              </div>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            class="pr-2"
            cols="6"
        >
          <v-autocomplete
              v-model="dworker"
              :items="$yesno"
              density="compact"
              item-title="l"
              item-value="v"
              label="Dosimetry reports"
              variant="outlined"
          ></v-autocomplete>
        </v-col>
        <v-col
            class="pl-2"
            cols="6"
        >
          <v-autocomplete
              v-model="sworker"
              :items="$yesno"
              density="compact"
              item-title="l"
              item-value="v"
              label="Surveyor reports"
              variant="outlined"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
          class="ma-1 pa-0"
          variant="outlined"
          width="30%"
          @click="goToEvents"
      >
        Close
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
          class="ma-1 pa-0"
          variant="outlined"
          width="30%"
          @click="saveAndClose"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style scoped>
.custom-selection {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
</style>