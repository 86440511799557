<script>
import { signInWithEmailAndPassword } from 'firebase/auth';
import iicepStore from "@/iicepStore.js";
import {authApp} from "@/main.js";
import {apiClientForm, getUserData, initializeDataLoad, objectToFormData, resetPwd, save911} from "@/services.js";
import ConfirmDialogue from "@/components/ConfirmDialogue.vue";

export default {
  components: {ConfirmDialogue},
  methods: {
    async confirmResetPwd(email) {
      try {
        const result = await this.$refs.confirmPwdReset.open({
          title: 'Confirm Password Reset',
          message: `New Password for <strong>${email}</strong> \n will be generated and sent to you`
        });
        if (result) {
          await this.recoverPassword();
        }
      } catch (err) {
      }
    },
    async tryLogIn () {
      try {
        localStorage.setItem('logged', '0');
        const response = await signInWithEmailAndPassword(authApp, this.email, this.password);
        localStorage.setItem('umail', this.email);
        const resp = await getUserData();
        const data = resp.data;
        if (data && data.ulist && data.ulist.length>0) {
          this.munilist=[];
          this.muniposlist={};
          this.userobj = {} ;
          if (data.ulist.length>0) {
            this.userobj.MEMBER_ID = data.ulist[0].ID ;
            this.userobj.FIRST_NAME = data.ulist[0].FirstName ;
            this.userobj.LAST_NAME = data.ulist[0].LastName ;
            this.userobj.WORK = data.ulist[0].Work ;
            this.userobj.CELL = data.ulist[0].Cell ;
            this.userobj.email = this.email ;
          }
          data.ulist.forEach(uel => {
            if (this.inMuniList(uel)) {
              this.muniposlist[uel.MuniCode.toString()].push({rv:uel.Role,rn:uel.Label});
            } else {
              let kkey = uel.MuniCode.toString();
              this.muninames[kkey] = uel.MuniName;
              this.munilist.push({code:kkey, name:uel.MuniName});
              this.muniposlist[kkey]= [{rv:uel.Role,rn:uel.Label}];
            }
          });
          this.currentMuni=this.munilist[0].code;
          this.currentRole=this.muniposlist[this.currentMuni][0].rv;
          this.showStep2=(this.munilist.length>0);
          this.mtextId=0;
        }
      } catch ({}) {
        this.mtextId=1;
      }
    },
    realLogin: async function () {
      let retval = false;
      this.currentRole = this.muniposlist[this.currentMuni][0].rv ;
      const rlabel = this.muniposlist[this.currentMuni][0].rn ;
      const udata = {
        'MEMBER_ID' : this.userobj.MEMBER_ID,
        'MEMBER_MAIL' : this.userobj.email,
        'ROLE': this.currentRole,
        'ROLELAB': rlabel,
        'MCODE': this.currentMuni,
        'MNAME': this.muninames[this.currentMuni],
        'WORK' : this.userobj.WORK,
        'CELL' : this.userobj.CELL,
        'FIRST_NAME':this.userobj.FIRST_NAME,
        'LAST_NAME': this.userobj.LAST_NAME,
        'MODE': this.currentVersion
      };
      try {
        const response = await apiClientForm.post('register.php', objectToFormData(udata))
        this.dialog=false;
        if (response.data.status === "OK") {
          iicepStore.data.currentVersionLabel = iicepStore.data.versionLabels[this.currentVersion];
          this.userobj['MODE']=this.currentVersion;
          this.userobj['MuniCode']=this.currentMuni;
          this.userobj['MuniName']=this.muninames[this.currentMuni];
          this.userobj['CountyCode']=response.data.data['CCODE'];
          this.userobj['CountyName']=response.data.data['CNAME'];
          this.$store.commit('setLoggedUserObj', this.userobj);
          localStorage.setItem('ICSEventID', null);
          localStorage.setItem('ICSEventName', null);
          localStorage.setItem("currentVersion", this.currentVersion);
          localStorage.setItem("currentMuniCode", this.currentMuni);
          localStorage.setItem("currentMuniName", this.muninames[this.currentMuni]);
          localStorage.setItem("currentCountyCode", response.data.data['CCODE']);
          localStorage.setItem("currentCountyName", response.data.data['CNAME']);
          this.$store.commit('setVersionLabel', this.$versionLabels[this.currentVersion]);
          this.$store.commit('setICSEvent',null);
          const intRole = parseInt(response.data.data['ROLE']);
          this.$store.commit('setUserESF', intRole);
          this.$store.commit('setAccessLevel',this.$rolelevels[intRole]);
          this.$store.commit('setUserRoles', this.$ics214roles[intRole]);
          localStorage.setItem('logged', '1');
          initializeDataLoad();
          const intendedDestination = this.$store.getters.getIntendedDestination?this.$store.getters.getIntendedDestination:'/' ;
          if (intendedDestination) {
            this.$store.commit('clearIntendedDestination');
            await this.$router.push(intendedDestination);
          }
          this.dialog = false;
          retval = true;
        } else {
          this.$store.commit('setLoggedUserObj', null);
          localStorage.setItem('logged', '0');
        }
      } catch ({}) {
        this.$store.commit('setLoggedUserObj', null);
        localStorage.setItem('logged', '0');
        this.mtextId=5;
      }
      return retval;
    },
    async recoverPassword() {
      const rslt  = await resetPwd(this.email);
      if (rslt.Status === 'Error') {
        this.mtextId=4;
      } else {
        this.mtextId=6;
      }
    },
    inMuniList: function (obj) {
      let inval = false;
      this.munilist.forEach(mel => {
        if (obj['MuniName'] === mel.name && obj.MuniCode.toString() === mel.code) {
          inval = true;
        }
      })
      return inval;
    }
  },
  computed: {
    dlgwid() {
      if (this.$vuetify.display.name==='xs') {
        return '90vw';
      } else if (this.$vuetify.display.name==='sm') {
        return '70vw';
      } else if (this.$vuetify.display.name==='md') {
        return '40vw';
      } else if (this.$vuetify.display.name==='lg') {
        return '30vw';
      } else {
        return '25vw';
      }
    },
  },
  data: () => ({
    email: '',
    password: '',
    dialog: false,
    visible: false,
    munilist:[],
    muniposlist: [],
    muninames: {},
    userobj: {},
    currentMuni: null,
    currentRole: null,
    currentVersion: '',
    showStep2:false,
    mtextId: 0,
    mtextArr: ['','Bad Email or Password', 'Please enter your email and password.',
      'Please enter your email','There was a problem recovering your password', 'There was a problem logging you in',
      'Your new password has been sent to your email'],
    iicepVersions: [{v:'IICEP ACTIVE', k:'ACTIVE'},{v:'IICEP Practice&Exercise', k:'DEMO'}],
  }),
  created() {
    this.dialog=!this.$store.getters.isLoggedUser;
  },
  mounted() {
    this.showStep2=false;
  }
}
</script>
<template>
  <v-row >
    <v-dialog
        v-model="dialog"
        persistent
        :width="dlgwid"
    >
      <v-card>
        <v-card-title class="text-center mt-3"> Log In </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col
                  class="pa-0 px-2"
              >
                <v-text-field
                    density="compact"
                    placeholder="Email address"
                    prepend-inner-icon="mdi-email-outline"
                    variant="outlined"
                    v-model="email"
                    @input="email = email.toLowerCase()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-0 px-2"
              >
                <v-text-field
                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="visible ? 'text' : 'password'"
                    density="compact"
                    placeholder="Enter your password"
                    prepend-inner-icon="mdi-lock-outline"
                    variant="outlined"
                    v-model="password"
                    @click:append-inner="visible = !visible"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                    variant="outlined"
                    density="comfortable"
                    v-model="currentVersion"
                    :items="iicepVersions"
                    item-title="v"
                    item-value="k"
                    label="Active OR Practice&Exercise"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col><span class="text-body-2 redcolor">{{mtextArr[mtextId]}}</span></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg=7 class="d-flex justify-space-evenly align-center">
                <v-btn
                    v-show="!showStep2"
                    class="mx-1"
                    variant="outlined"
                    :disabled="!email || !password || !currentVersion"
                    @click="tryLogIn"
                > Log In
                </v-btn>
              </v-col>
              <v-col cols="12" lg=5 class="d-flex justify-center align-center">
                <v-btn
                    v-show="!showStep2"
                    class="mx-1 "
                    variant="text"
                    density="compact"
                    :disabled="!email"
                    @click="confirmResetPwd(email)"
                    clickssss="recoverPassword"
                > Reset Your Password
                </v-btn>
                <ConfirmDialogue ref="confirmPwdReset"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                    v-show="showStep2"
                    variant="outlined"
                    density="comfortable"
                    v-model="currentMuni"
                    :items="munilist"
                    item-title="name"
                    item-value="code"
                    label="Jurisdiction"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
              v-show="showStep2"
              @click="realLogin"
              outlined
          >
            PROCEED TO IICEP
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<style scoped>
.redcolor {
  color: #E53935;
}
</style>