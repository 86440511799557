import {circle,polygon} from "@turf/turf";
import {rhumbDestination} from "@turf/rhumb-destination";

export function iso_explosives(ev_id,ev_name,lat,lng,esize) {
    const features = [];
    if (!!esize) {
        features.push(exppoly1(lat, lng, esize));
        features.push(exppoly2(lat, lng, esize));
        features.push(exppoly3(lat, lng, esize));
    }
    features.forEach(function(feature) {
        feature.properties.event_id = ev_id;
        feature.properties.event_name = ev_name;
    })
    return JSON.parse(JSON.stringify(features));
}
export function iso_hazmat(ev_id,ev_name,lat,lng,wdir,ssize,stime) {
    const features = [];
    if (ssize >= 0 && wdir >= 0) {
        features.push(hazpoly1(lat, lng, ssize));
        features.push(hazpoly2(lat, lng, wdir, ssize, stime));
        features.forEach(function (feature) {
            feature.properties.event_id = ev_id;
            feature.properties.event_name = ev_name;
        })
    }
    console.log(JSON.parse(JSON.stringify(features)));
    return JSON.parse(JSON.stringify(features));
}
export function iso_radioactive(ev_id,ev_name,lat,lng,wdir) {
    const features = [];
    features.push(radpoly1(lat, lng, wdir));
    features.push(radpoly2(lat, lng, wdir));
    features.push(radpoly3(lat, lng, wdir));
    features.push(radpoly4(lat, lng));
    features.push(radpoly5(lat, lng));
    features.push(radpoly6(lat, lng));
    features.push(radpoly7(lat, lng));
    features.push(radpoly8(lat, lng));
    features.push(radpoly9(lat, lng, wdir));
    features.push(radpoly10(lat, lng, wdir));
    features.push(radpoly11(lat, lng, wdir));
    features.forEach(function(feature) {
        feature.properties.event_id = ev_id;
        feature.properties.event_name = ev_name;
    })
    return JSON.parse(JSON.stringify(features));
}

function exppoly1(lat, lng, ssize) {
    const centerCoordinates = [lng, lat];
    const radius = isopleths.explosives.expdist[ssize][0];
    const geojsonFeature = circle(centerCoordinates, radius, {steps: 64, units: "meters"});
    geojsonFeature.properties = {
        fillColor: '#ff0000',
        fillOpacity: 0.30,
        strokeColor: '#ff0000',
        strokeOpacity: 0.4,
        strokeWeight: 2,
        zIndex: 1000
    };
    return geojsonFeature;
}

function exppoly2(lat, lng, ssize) {
    const centerCoordinates = [lng, lat];
    const radius = isopleths.explosives.expdist[ssize][1];
    const geojsonFeature = circle(centerCoordinates, radius, {steps: 64, units: "meters"});
    geojsonFeature.properties = {
        fillColor: '#ff8080',
        fillOpacity: 0.30,
        strokeColor: '#ff8080',
        strokeOpacity: 0.3,
        strokeWeight: 2,
        zIndex: 900
    };
    return geojsonFeature;
}

function exppoly3(lat, lng, ssize) {
    const centerCoordinates = [lng, lat];
    const radius = 2*isopleths.explosives.expdist[ssize][1];
    const geojsonFeature = circle(centerCoordinates, radius, {steps: 64, units: "meters"});
    geojsonFeature.properties = {
        fillColor: '#ffff00',
        fillOpacity: 0.20,
        strokeColor: '#ffff00',
        strokeOpacity: 0.2,
        strokeWeight: 2,
        zIndex: 800
    };
    return geojsonFeature;
}

function hazpoly1(lat, lng, ssize) {
    const centerCoordinates = [lng, lat];
    const radius = isopleths.hazmat.iso2[ssize];
    const geojsonFeature = circle(centerCoordinates, radius, {steps: 64, units: "meters"});
    geojsonFeature.properties = {
            fillColor: '#ff0000',
            fillOpacity: 0.35,
            strokeColor: '#ff0000',
            strokeOpacity: 1,
            strokeWeight: 2,
            zIndex: 100
    };
    return geojsonFeature;
}

function hazpoly2(lat, lng, wdir, ssize, stime) {
    const wdir1 = (wdir + 180) % 360;
    const a1 = (wdir1 - 90 + 360) % 360;
    const a2 = (wdir1 + 90) % 360;
    let a0 = (wdir1 + 30) % 360;
    const pnts = [];
    const cenCoords = [lng, lat];
    pnts.push(rhumbDestination(cenCoords, isopleths.hazmat.iso2[ssize] / 1000, a2).geometry.coordinates);
    for (let ii = 0; ii < 13; ii++) {
        a0 = (a0 - 5 + 360) % 360;
        pnts.push(
            rhumbDestination(cenCoords, isopleths.hazmat.iso1[ssize][stime] / 1000, a0).geometry.coordinates
        );
    }
    pnts.push(rhumbDestination(cenCoords, isopleths.hazmat.iso2[ssize] / 1000, a1).geometry.coordinates);
    pnts.push(pnts[0]);
    const geojsonFeature = polygon([pnts]);
    geojsonFeature.properties = {
        fillColor: '#ffff00',
        fillOpacity: 0.3,
        strokeColor: '#ffff00',
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 50
    };
    return geojsonFeature;
}

function radpoly1(lat, lng, wdir1) {
    const wdir = (wdir1 + 180) % 360;
    const cenCoords = [lng, lat];
    const p1 = [];
    p1.push(cenCoords);
    p1.push(rhumbDestination(cenCoords, 10 * 1609.34 / 1000, wdir - 33.75).geometry.coordinates);
    p1.push(rhumbDestination(cenCoords, 10 * 1609.34 / 1000, wdir - 11.25).geometry.coordinates);
    p1.push(p1[0]);
    const geojsonFeature = polygon([p1]);
    geojsonFeature.properties = {
        fillColor: '#ffff00',
        fillOpacity: 0.3,
        strokeColor: '#ffff00',
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 50
    };
    return geojsonFeature;
}

function radpoly2(lat, lng, wdir1) {
    const wdir = (wdir1 + 180) % 360;
    const cenCoords = [lng, lat];
    const p1 = [];
    p1.push(cenCoords);
    p1.push(rhumbDestination(cenCoords, 10 * 1609.34 / 1000, wdir + 33.75).geometry.coordinates);
    p1.push(rhumbDestination(cenCoords, 10 * 1609.34 / 1000, wdir + 11.25).geometry.coordinates);
    p1.push(p1[0]);
    const geojsonFeature = polygon([p1]);
    geojsonFeature.properties = {
        fillColor: '#ffff00',
        fillOpacity: 0.3,
        strokeColor: '#ffff00',
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 50
    };
    return geojsonFeature;
}

function radpoly3(lat, lng, wdir1) {
    const wdir = (wdir1 + 180) % 360;
    const cenCoords = [lng, lat];
    const p1 = [];
    p1.push(cenCoords);
    p1.push(rhumbDestination(cenCoords, 10 * 1609.34 / 1000, wdir - 11.25).geometry.coordinates);
    p1.push(rhumbDestination(cenCoords, 10 * 1609.34 / 1000, wdir + 11.25).geometry.coordinates);
    p1.push(p1[0]);
    const geojsonFeature = polygon([p1]);
    geojsonFeature.properties = {
        fillColor: "#ff0000",
        fillOpacity: 0.3,
        strokeColor: "#ff0000",
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 90
    };
    return geojsonFeature;
}

function radpoly4(lat, lng) {
    const cenCoords = [lng, lat];
    const geojsonFeature = circle(cenCoords, 805 / 1000, { steps: 64 });
    geojsonFeature.properties = {
        fillColor: "#ff0000",
        fillOpacity: 0.3,
        strokeColor: "#ff0000",
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 100
    };
    return geojsonFeature;
}

function radpoly5(lat, lng) {
    const cenCoords = [lng, lat];
    const geojsonFeature = circle(cenCoords, 1609 / 1000, { steps: 64 });
    geojsonFeature.properties = {
        fillColor: "#ffffff",
        fillOpacity: 0.1,
        strokeColor: "#ffffff",
        strokeOpacity: 0.7,
        strokeWeight: 2,
        zIndex: 45
    };
    return geojsonFeature;
}

function radpoly6(lat, lng) {
    const cenCoords = [lng, lat];
    const geojsonFeature = circle(cenCoords, 8045 / 1000, { steps: 64 });
    geojsonFeature.properties = {
        fillColor: "#ffffff",
        fillOpacity: 0.1,
        strokeColor: "#ffffff",
        strokeOpacity: 0.7,
        strokeWeight: 2,
        zIndex: 40
    };
    return geojsonFeature;
}

function radpoly7(lat, lng) {
    const cenCoords = [lng, lat];
    const geojsonFeature = circle(cenCoords, 16090 / 1000, { steps: 64 });
    geojsonFeature.properties = {
        fillColor: "#ffffff",
        fillOpacity: 0.1,
        strokeColor: "#ffffff",
        strokeOpacity: 0.7,
        strokeWeight: 2,
        zIndex: 30
    };
    return geojsonFeature;
}

function radpoly8(lat, lng) {
    const cenCoords = [lng, lat];
    const geojsonFeature = circle(cenCoords, 80450 / 1000, { steps: 64 });
    geojsonFeature.properties = {
        fillColor: "#c0c0c0",
        fillOpacity: 0.15,
        strokeColor: "#c0c0c0",
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 20
    };
    return geojsonFeature;
}

function radpoly9(lat, lng, wdir1) {
    const wdir = (wdir1 + 180) % 360;
    const cenCoords = [lng, lat];
    const p1 = [];
    p1.push(cenCoords);
    p1.push(rhumbDestination(cenCoords, 50 * 1609.34 / 1000, wdir - 33.75).geometry.coordinates);
    p1.push(rhumbDestination(cenCoords, 50 * 1609.34 / 1000, wdir - 11.25).geometry.coordinates);
    p1.push(p1[0]);
    const geojsonFeature = polygon([p1]);
    geojsonFeature.properties = {
        fillColor: "#ffff00",
        fillOpacity: 0.15,
        strokeColor: "#ffff00",
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 49
    };
    return geojsonFeature;
}

function radpoly10(lat, lng, wdir1) {
    const wdir = (wdir1 + 180) % 360;
    const cenCoords = [lng, lat];
    const p1 = [];
    p1.push(cenCoords);
    p1.push(rhumbDestination(cenCoords, 50 * 1609.34 / 1000, wdir + 33.75).geometry.coordinates);
    p1.push(rhumbDestination(cenCoords, 50 * 1609.34 / 1000, wdir + 11.25).geometry.coordinates);
    p1.push(p1[0]);
    const geojsonFeature = polygon([p1]);
    geojsonFeature.properties = {
        fillColor: "#ffff00",
        fillOpacity: 0.15,
        strokeColor: "#ffff00",
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 49
    };
    return geojsonFeature;
}

function radpoly11(lat, lng, wdir1) {
    const wdir = (wdir1 + 180) % 360;
    const cenCoords = [lng, lat];
    const p1 = [];
    p1.push(cenCoords);
    p1.push(rhumbDestination(cenCoords, 50 * 1609.34 / 1000, wdir - 11.25).geometry.coordinates);
    p1.push(rhumbDestination(cenCoords, 50 * 1609.34 / 1000, wdir + 11.25).geometry.coordinates);
    p1.push(p1[0]);
    const geojsonFeature = polygon([p1]);
    geojsonFeature.properties = {
        fillColor: "#ff0000",
        fillOpacity: 0.15,
        strokeColor: "#ff0000",
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 49
    };
    return geojsonFeature;
}

export const isopleths = {
    radio: {},
    hazmat: {
        iso1: [[1500.0, 400.0],[7900.0, 3000.0]],
        iso2: [60, 500]
    },
    explosives: {
        expdist: [
            [21, 365],
            [34, 518],
            [46, 563],
            [98, 579],
            [122, 731],
            [195, 1158],
            [269, 1554],
            [475, 2834]
        ],
        expsize: [
            '5 lbs.', '20 lbs.', '50 lbs.', '500 lbs.', '1,000 lbs.', '4,000 lbs.', '10,000 lbs.', '50,000 lbs.'],
        expdesc: [
            'Pipe Bomb', 'Suicide Bomber', 'Briefcase/Suitcase', 'Car', 'SUV/Van',
            'Small Delivery Truck', 'Container/Water Truck', 'Semi-Trailer'],
        feetdist: [
            [70, 1200],
            [110, 1700],
            [150, 1850],
            [320, 1900],
            [400, 2400],
            [640, 3800],
            [860, 5100],
            [1570, 9300]
        ]
    }
}
