<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css"
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import {
  CircleMode,
  DragCircleMode,
  DirectMode,
  SimpleSelectMode
} from '@/mapbox-gl-draw-circle';
import {mapState} from "vuex";
mapboxgl.accessToken = 'pk.eyJ1Ijoic2RhbGFrb3YiLCJhIjoiY2o1eGxvdnRzMDVhOTJ4bnczd3lpMTRiMiJ9.lb016P2ofij1axIWoobBCQ';
import {mapOemConfig} from "@/mapOemConfig.js";
import {objectToFormData, saveAnno} from "@/services.js";

class MapButton {
  constructor(onClick, img, id) {
    this.onClick = onClick;
    this.imgurl = img;
    this.btnid = id;
  }
  setIcon(img) {
    this.imgurl = img;
    const buttonImg = document.querySelector(`#${this.btnid} img`);
    if (buttonImg) {
      buttonImg.src = img;
    }
  }
  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    const button = document.createElement('button');
    button.id = this.btnid;
    const buttonImg = document.createElement('img');
    buttonImg.src = this.imgurl;
    button.appendChild(buttonImg);
    button.onclick = this.onClick;
    this.container.appendChild(button);
    return this.container;
  }
  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

class MapButtonArea {
  constructor() {
  }
  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group custom-control-map';
    this.toggleButton = document.createElement('button');
    this.toggleButton.className = 'icon-button-map';
    this.toggleButton.id = '_map_edit';
    const svgIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svgIcon.setAttribute('width', '20');
    svgIcon.setAttribute('height', '20');
    svgIcon.setAttribute('viewBox', '0 0 24 24');
    svgIcon.setAttribute('fill', 'none');
    svgIcon.setAttribute('stroke', 'currentColor');
    svgIcon.setAttribute('stroke-width', '2');
    svgIcon.setAttribute('stroke-linecap', 'round');
    svgIcon.setAttribute('stroke-linejoin', 'round');
    const path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path1.setAttribute('d', 'M12 20h9');
    svgIcon.appendChild(path1);
    const path2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path2.setAttribute('d', 'M16.5 3a2.121 2.121 0 0 1 3 0l1.5 1.5a2.121 2.121 0 0 1 0 3L7 21H3v-4L16.5 3z');
    svgIcon.appendChild(path2);
    this.toggleButton.appendChild(svgIcon);
    this.toggleButton.onclick = () => this.toggleVisibility();
    this.contentDiv = document.createElement('div');
    this.contentDiv.style.display = 'none';
    this.contentDiv.innerHTML = `
      <div class="row-map">
        <span>&nbsp;</span>
        <button class="icon-button-map" id="draw_line">
          <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"
             width="20"
             height="20">
             <path d="m13.5 3.5c-1.4 0-2.5 1.1-2.5 2.5 0 .3 0 .6.2.9l-3.8 3.8c-.3-.1-.6-.2-.9-.2-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5c0-.3 0-.6-.2-.9l3.8-3.8c.3.1.6.2.9.2 1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5z"/>
          </svg>
        </button>
        <span>&nbsp;</span>
        <button class="icon-button-map" id="draw_polygon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width="20"
            height="20"
          >
            <path d="m15 12.3v-4.6c.6-.3 1-1 1-1.7 0-1.1-.9-2-2-2-.7 0-1.4.4-1.7 1h-4.6c-.3-.6-1-1-1.7-1-1.1 0-2 .9-2 2 0 .7.4 1.4 1 1.7v4.6c-.6.3-1 1-1 1.7 0 1.1.9 2 2 2 .7 0 1.4-.4 1.7-1h4.6c.3.6 1 1 1.7 1 1.1 0 2-.9 2-2 0-.7-.4-1.4-1-1.7zm-8-.3v-4l1-1h4l1 1v4l-1 1h-4z"/>
          </svg>
        </button>
        <span>&nbsp;</span>
        <button class="icon-button-map" id="draw_marker">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width="20"
            height="20"
            >
            <path d="m10 2c-3.3 0-6 2.7-6 6s6 9 6 9 6-5.7 6-9-2.7-6-6-6zm0 2c2.1 0 3.8 1.7 3.8 3.8 0 1.5-1.8 3.9-2.9 5.2h-1.7c-1.1-1.4-2.9-3.8-2.9-5.2-.1-2.1 1.6-3.8 3.7-3.8z"/>
          </svg>
        </button>
        <span>&nbsp;</span>
      </div>
      <div class="row-map hidediv" id="line-block">
      </div>
      <div class="row-map hidediv" id="point-block">
      </div>
      <div class="row-map hidediv" id="polygon-block">
      </div>
      <div class="row-map">
        <span>&nbsp;</span>
        <button class="icon-button-map" id="_map_delete">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M3 6h18" />
            <path d="M5 6v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6" />
            <path d="M9 10v8" />
            <path d="M15 10v8" />
            <path d="M4 6l1-4h14l1 4" />
          </svg>
        </button>
        <span>&nbsp;&nbsp;</span>
        <button class="icon-button-map" id="_map_save">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
            <polyline points="17 21 17 13 7 13 7 21" />
            <polyline points="7 3 7 8 15 8" />
            <line x1="9" y1="21" x2="15" y2="21" />
          </svg>
        </button>
        <span>&nbsp;&nbsp;</span>
        <button class="icon-button-map" id="_map_cancel">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </button>
      <span>&nbsp;</span>
      </div> `;
    this.container.appendChild(this.toggleButton);
    this.container.appendChild(this.contentDiv);
    this.deleteButton = this.contentDiv.querySelector('#_map_delete');
    this.saveButton = this.contentDiv.querySelector('#_map_save');
    this.cancelButton = this.contentDiv.querySelector('#_map_cancel');
    this.drawLineButton = this.contentDiv.querySelector('#draw_line');
    this.drawPointButton = this.contentDiv.querySelector('#draw_marker');
    this.drawPolygonButton = this.contentDiv.querySelector('#draw_polygon');
    this.drawIconBlock = this.contentDiv.querySelector('#point-block');
    mapOemConfig.annoIcons.forEach(icon => {
      const btndiv = document.createElement('div');
      btndiv.className = 'row-map-row';
      btndiv.innerHTML = `<button id="_map_pnt_${icon.value}" class="draw_point"><img src="./assets/${icon.icon}" alt="${icon.label}"/></button><span>${icon.label}</span>`;
      this.drawIconBlock.appendChild(btndiv);
    });
    this.drawLineBlock = this.contentDiv.querySelector('#line-block');
    mapOemConfig.annoLines.forEach(line => {
      const btndiv = document.createElement('div');
      btndiv.className = 'row-map-row';
      btndiv.innerHTML = `<button id="_map_line_${line.value}" class="draw_line"><div style="width: 20px; height: 5px; background-color: ${line.color}; display: inline-block; margin-right: 10px;"></div></button><span>${line.label}</span>`;
      this.drawLineBlock.appendChild(btndiv);
    });
    this.drawPolyBlock = this.contentDiv.querySelector('#polygon-block');
    mapOemConfig.annoPolygons.forEach(poly => {
      const btndiv = document.createElement('div');
      btndiv.className = 'row-map-row';
      btndiv.innerHTML = `<button id="_map_poly_${poly.value}" class="draw_poly"><div style="width: 30px; height: 30px; background-color: ${poly.color}; border: 2px solid ${poly.bcolor}; display: inline-block; margin-right: 10px;"></div></button><span>${poly.label}</span>`;
      this.drawPolyBlock.appendChild(btndiv);
    });
    return this.container;
  }

  toggleVisibility() {
    this.isVisible = !this.isVisible;
    this.contentDiv.style.display = this.isVisible ? 'block' : 'none';
    this.contentDiv.style.height = this.isVisible ? 'auto' : '0px';
    this.toggleButton.style.display = this.isVisible ? 'none' : 'block';
  }
  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

export default {
  name: 'mapwin',
  data: () => ({
    resizeObserver: null,
    initComplete1: false,
    initComplete2: false,
    tempPin: null,
    maplayers: [],
    popup: null,
    current_map: 'satellite',
    btn_icons: {
      'street': './assets/satellite.png',
      'satellite': './assets/street.png'
    },
    mapTypeControl: null,
    mapLayerControl: null,
    incident_pins: [],
    event_pins: [],
    rr_pins: [],
    sourcedefs: mapOemConfig.sourcedefs,
    layerdefs: mapOemConfig.layerdefs,
    editWidget: null,
    selectedGeom: '',
    selectedType: '',
    layerdialog: false,
    mapOemConfig: mapOemConfig,
    drawLineButton: null,
    drawPointButton: null,
    drawPolygonButton: null,
    selected_anno: null,
    selected_inc: null,
    selected_rr: null,
    selected_own_anno: null,
    selected_foreign_anno: null,
    selected_own_inc: null,
  }),
  computed: {
    ...mapState(['own_annos','foreign_annos','inc_ftrs','ev_ftrs','rr_ftrs', 'tempPinCoords', 'isopleth_ftrs'])
  },
  watch: {
    tempPinCoords(newCoords) {
      console.log('watch:', JSON.stringify(newCoords));
      if (newCoords) {
        if (newCoords.length === 0) {
          newCoords = this.map.getCenter();
        }
        if (!this.tempPin) {
          this.tempPin = new mapboxgl.Marker({draggable: true})
              .setLngLat(newCoords)
              .addTo(this.map);
          this.tempPin.on('dragend', () => {
            const lngLat = this.tempPin.getLngLat();
            this.$store.commit('setTempCoords', lngLat);
          });
        } else {
          this.tempPin.setLngLat(newCoords);
        }
        this.map.setCenter(newCoords);
      } else {
        if (this.tempPin) {
          this.tempPin.remove();
          this.tempPin = null;
        }
      }
    },
    isopleth_ftrs(newdata) {
      this.reloadSource('iso_ftrs_src', newdata);
    },
    own_annos(newdata) {
      this.reloadSource('anno_own_src', newdata)
    },
    foreign_annos(newdata) {
      this.reloadSource('anno_foreign_src', newdata)
    },
    inc_ftrs(newdata) {
      this.reloadSource('inc_ftrs_src', newdata);
    },
    ev_ftrs(newdata) {
      this.reloadSource('ev_ftrs_src', newdata);
    },
    rr_ftrs(newdata) {
      this.reloadSource('rr_ftrs_src', newdata);
    },
  },
  mounted() {
    this.map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: "mapbox://styles/sdalakov/cltcnxc95018p01pifml74que",
      center: [-75.22, 39.55],
      zoom: 10,
    });
    this.current_map = mapOemConfig.initialMode;
    this.draw = new MapboxDraw({
      defaultMode: "simple_select",
      userProperties: true,
      displayControlsDefault: false,
      modes: {
        ...MapboxDraw.modes,
        draw_rectangle: DrawRectangle,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode
      },
      styles: mapOemConfig.drawStyles,
    });
    this.map.on('load', () => {
      try {
        this.setMapMode(this.current_map);
        this.map.fitBounds(mapOemConfig.mapInitialView, {padding:10});
        Object.keys(this.sourcedefs).forEach(k => {
          this.map.addSource(k, this.sourcedefs[k]);
        });
        this.layerdefs.forEach(lr => {
          this.map.addLayer(lr);
        })
        this.setLayerVisibility();
        this.initComplete1 = true;
        this.addMarkerIcons().then(() => {
          this.initComplete2 = true;
          this.reloadSource('inc_ftrs_src', this.inc_ftrs);
          this.reloadSource('anno_own_src', this.own_annos);
          this.reloadSource('anno_foreign_src', this.foreign_annos);
          this.reloadSource('ev_ftrs_src', this.ev_ftrs);
          this.reloadSource('rr_ftrs_src', this.rr_ftrs);
          this.reloadSource('iso_ftrs_src', this.isopleth_ftrs);
          /*
          this.reloadIncidents(this.incdata);
        this.reloadSource('anno_ftrs_src', this.annoFtrSrc);
        this.reloadSource('inc_ftrs_src', this.inc_ftrs);
        this.createEvents('inc_points');
           */
        });
        mapOemConfig.iicepLayers.forEach(lr => {
          if (lr.type==='layer') {
            this.setLayerEvents(lr.id,lr.label);
          } else if (lr.type==='group'){
            if (lr.type === 'group') {
              lr.members.forEach(mem => {
                this.setLayerEvents(mem,lr.label);
              })
            }
          }
        })
      } catch (e) {
       console.log(e);
      }
    });
    this.map.on('draw.create', (e) => {
      console.log('Draw Create');
      const featureId = e.features[0].id;
      if (this.selectedGeom === 'Point') {
        this.draw.setFeatureProperty(featureId, 'icon', this.selectedType);
        this.draw.setFeatureProperty(featureId, 'aType', lineObj.label);
        this.resetDrawButtons();
      }
      if (this.selectedGeom === 'LineString') {
        const lineObj=mapOemConfig.annoLines.find(l => l.value === this.selectedType);
        this.draw.setFeatureProperty(featureId, 'lineColor', lineObj.color);
        this.draw.setFeatureProperty(featureId, 'aType', lineObj.label);
      }
      if (this.selectedGeom === 'Polygon') {
        const polyObj=mapOemConfig.annoPolygons.find(l => l.value === this.selectedType);
        this.draw.setFeatureProperty(featureId, 'fillColor', polyObj.color);
        this.draw.setFeatureProperty(featureId, 'lineColor', polyObj.bcolor);
        this.draw.setFeatureProperty(featureId, 'aType', polyObj.label);
      }
    });
    this.mapTypeControl = new MapButton(this.toggleMapMode, './assets/street.png', '_oem_map_type');
    this.map.addControl(this.mapTypeControl, 'top-right');
    this.mapLayerControl = new MapButton(this.setMapLayers, './assets/layer.png', '_oem_layers');
    this.map.addControl(this.mapLayerControl, 'top-right');
    this.editWidget = new MapButtonArea();
    this.map.addControl(this.draw);
    this.map.addControl(this.editWidget, 'top-right');
    this.editWidget.toggleButton.onclick = () => this.startEdits();
    this.editWidget.saveButton.onclick = () => this.saveEdits();
    this.editWidget.cancelButton.onclick = () => this.cancelEdits();
    this.editWidget.deleteButton.onclick = () => this.deleteSelected();
    this.editWidget.drawLineButton.onclick = () => this.setDrawLine();
    this.editWidget.drawPointButton.onclick = () => this.setDrawPoint();
    this.editWidget.drawPolygonButton.onclick = () => this.setDrawPolygon();
    this.selectedType = '';
    this.selectedGeom = '';
    const linebuttons = this.editWidget.contentDiv.querySelectorAll('.draw_line');
    const pointbuttons = this.editWidget.contentDiv.querySelectorAll('.draw_point');
    const polygonbuttons = this.editWidget.contentDiv.querySelectorAll('.draw_poly');
    polygonbuttons.forEach(button => {
      button.onclick = () => {
        this.resetDrawButtons();
        button.classList.add('button-highlight-oem');
        this.selectedType=button.id.replace('_map_poly_', '');
        this.draw.changeMode('draw_polygon');
      }
    });
    linebuttons.forEach(button => {
      button.onclick = () => {
        this.resetDrawButtons();
        button.classList.add('button-highlight-oem');
        this.selectedType=button.id.replace('_map_line_', '');
        console.log('Selected type=',this.selectedType);
        this.draw.changeMode('draw_line_string');
      }
    });
    pointbuttons.forEach(button => {
      button.onclick = () => {
        this.resetDrawButtons();
        button.classList.add('button-highlight-oem');
        this.selectedType='im'+button.id.replace('_map_pnt_', '');
        this.draw.changeMode('draw_point');
      }
    })

    this.resizeObserver = new ResizeObserver(() => {
      this.map.resize();
    });
    this.resizeObserver.observe(this.$refs.mapContainer);
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    window.removeEventListener('resize', this.handleWindowResize);
    if (this.map) {
      this.map.remove();
    }
  },
  methods: {
    setDrawLine() {
      document.querySelector('#line-block').classList.remove('hidediv');
      document.querySelector('#point-block').classList.add('hidediv');
      document.querySelector('#polygon-block').classList.add('hidediv');
      this.selectedGeom = 'LineString';
    },
    setDrawPoint() {
      document.querySelector('#line-block').classList.add('hidediv');
      document.querySelector('#point-block').classList.remove('hidediv');
      document.querySelector('#polygon-block').classList.add('hidediv');
      this.selectedGeom = 'Point';
    },
    setDrawPolygon() {
      document.querySelector('#line-block').classList.add('hidediv');
      document.querySelector('#point-block').classList.add('hidediv');
      document.querySelector('#polygon-block').classList.remove('hidediv');
      this.selectedGeom = 'Polygon';
    },
    resetDrawButtons() {
      this.editWidget.contentDiv.querySelectorAll('.draw_poly').forEach(button => {
        button.classList.remove('button-highlight-oem');
      });
      this.editWidget.contentDiv.querySelectorAll('.draw_line').forEach(button => {
        button.classList.remove('button-highlight-oem');
      });
      this.editWidget.contentDiv.querySelectorAll('.draw_point').forEach(button => {
        button.classList.remove('button-highlight-oem');
      });
    },
    setLayerEvents(layername, label) {
      this.map.on('mouseenter', layername, (e) => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', layername, (e) => {
        this.map.getCanvas().style.cursor = '';
      });
      this.map.on('click',layername, (e) => {
        console.log('Object: ',JSON.stringify(e.features[0].properties));
        const coordinates = e.lngLat;
        let html = `<h3>${label}</h3><table>`;
        Object.keys(mapOemConfig[layername].attrib).forEach(k => {
          html = html + `<tr><td><strong>${mapOemConfig[layername].attrib[k]}</strong></td><td>${e.features[0].properties[k]}</td></tr>`;
        })
        html += '</table>';
        const iPopup=new mapboxgl.Popup({maxWidth: '500px'})
            .setLngLat(coordinates)
            .setHTML(html)
            .addTo(this.map);
      })
    },
    setMapMode: function(maptype) {
      Object.keys(mapOemConfig.modeLayersList).forEach( modekey => {
        mapOemConfig.modeLayersList[modekey].forEach(lr => {
          this.map.setLayoutProperty(lr, 'visibility', (modekey === maptype) ? 'visible':'none' )
        })
      })
      this.mapTypeControl.setIcon(this.btn_icons[maptype]);
    },
    toggleMapMode: function() {
      if (this.current_map==='satellite') {
        this.setMapMode('street');
        this.current_map= 'street';
      } else if (this.current_map==='street') {
        this.setMapMode('satellite');
        this.current_map= 'satellite';
      }
    },
    setMapLayers: function() {
      this.layerdialog=true;
    },
    applyLayerChanges: function() {
      this.layerdialog=false;
      this.setLayerVisibility();
    },
    handleWindowResize() {
      this.map.resize();
    },
    hideAnnos() {
      this.map.setLayoutProperty('anno_points_o', 'visibility', 'none');
      this.map.setLayoutProperty('anno_lines_o', 'visibility', 'none');
      this.map.setLayoutProperty('anno_polys_o', 'visibility', 'none');
      this.mapOemConfig.iicepLayers.forEach(layer => {
        if (layer.id === 'annos_o') {
          layer.state = false;
        }
      });
    },
    showAnnos() {
      this.map.setLayoutProperty('anno_points_o', 'visibility', 'visible');
      this.map.setLayoutProperty('anno_lines_o', 'visibility', 'visible');
      this.map.setLayoutProperty('anno_polys_o', 'visibility', 'visible');
      this.mapOemConfig.iicepLayers.forEach(layer => {
        if (layer.id === 'annos_o') {
          layer.state = true;
        }
      });
    },
    reloadSource(src, ftr_list) {
      console.log('Reload source: ', src);
      if (this.map && this.map.getSource(src)) {
        console.log('Reload list: ', ftr_list);
        let geojson;
        if (ftr_list && ftr_list.type === 'FeatureCollection' && Array.isArray(ftr_list.features)) {
          geojson = ftr_list;
        } else {
          geojson = {
            'type': 'FeatureCollection',
            'features': ftr_list || []
          };
        }
        this.map.getSource(src).setData(geojson);
      }
    },
    deleteSelected() {
      this.draw.trash();
    },
    startEdits() {
      this.editWidget.toggleVisibility();
      const ftrSet = {'type': 'FeatureCollection', 'features': JSON.parse(JSON.stringify(this.own_annos))};
      this.draw.set(ftrSet);
      this.draw.changeMode('simple_select');
      this.hideAnnos();
    },
    async saveEdits() {
      this.editWidget.toggleVisibility();
      const adata = this.draw.getAll();
      this.$store.commit('setOwnAnnoGeom', adata);
      console.log("Save edits: ", JSON.stringify(adata));
      let xdata = {mode: 'saveannopub', annos: JSON.stringify(adata.features)};
      await saveAnno(objectToFormData(xdata))
           .then(response => {
             console.log("Saved anno data");
           });
      this.draw.deleteAll();
      this.showAnnos();
    },
    cancelEdits() {
      this.editWidget.toggleVisibility();
      this.draw.deleteAll();
      this.showAnnos();
    },
    setLayerVisibility: function() {
      this.mapOemConfig.iicepLayers.forEach(lr => {
        let lstate = lr.state ? 'visible' : 'none';
        if (lr.type === 'layer') {
          if (this.map.getLayer(lr.id)) {
            this.map.setLayoutProperty(lr.id, 'visibility', lstate)
          }
        }
        if (lr.type === 'group') {
          lr.members.forEach(mem => {
            if (this.map.getLayer(mem)) {
              this.map.setLayoutProperty(mem, 'visibility', lstate);
            }
          })
        }
      });
    },
    addMarkerIcons() {
      const combinedArray = [...mapOemConfig.annoIcons, ...mapOemConfig.incidentIcons, ...mapOemConfig.eventIcons];
      let self = this;
      const imagePromises = combinedArray.map(irec => {
        return new Promise((resolve, reject) => {
          self.map.loadImage(`./assets/${irec.icon}`, (error, image) => {
            if (error) {
              reject(error);
            } else {
              const imgname = 'im' + irec.value;
              if (!self.map.hasImage(imgname)) {
                self.map.addImage(imgname, image);
              }
              resolve();
            }
          });
        });
      });
      return Promise.all(imagePromises);
    }
  },
}
</script>
<template>
  <div ref="mapContainer" class="map-container"></div>
  <v-dialog v-model="layerdialog" max-width="300px" theme="light">
    <v-card>
      <v-card-title>Layers</v-card-title>
      <v-card-text>
        <v-row no-gutters v-for="(layer, index) in mapOemConfig.iicepLayers" :key="index">
          <v-col cols="12">
            <v-checkbox
                density="compact"
                hide-details
                v-model="layer.state"
                :label="layer.label"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          debsity="condensed"
          @click="applyLayerChanges"
          outlined
          >APPLY</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.map-container {
  flex: 1;
}
.custom-control-map div {
  padding-top: 5px;
}
.custom-control-map .row-map {
  display: flex;
  align-items: center; /* Vertically aligns items */
  justify-content: space-between; /* Distributes items evenly */
  margin-bottom: 5px;
}
.custom-control-map .row-map span {
  flex: 1; /* Allows the span to use remaining space */
  text-align: center; /* Centers the text */
}
.custom-control-map .row-map button {
  margin-left: 5px;
  margin-right: 5px;
}
.icon-button-map {
  width: 29px;
  height: 29px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icon-button-map svg {
  width: auto; /* Ensures the SVG scales within the padding */
  height: auto;
  display: block;
  margin: auto;
}
.hidediv {
  display: none !important;
}
#point-block,#line-block,#polygon-block {
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  align-items: start;
}
.row-map-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row-map-row button {
  height: unset;
  width: unset;
}
.draw_point, .draw_line, .draw_poly {
  border-radius: 0 !important;
}
</style>