export const mapOemConfig = {
    initialMode: 'satellite',
    modeLayersList: {
        street: [
            'road-exit-shield', 'road-number-shield', 'road-intersection', 'road-secondary-tertiary', 'road-street-low',
            'road-street', 'road-major-link', 'road-minor-link', 'road-minor', 'road-construction', 'turning-feature',
            'road-motorway-trunk-case', 'road-major-link-case', 'road-primary-case', 'road-street-case', 'road-minor-case',
            'turning-feature-outline', 'road-secondary-tertiary-case', 'road-minor-link-case'
        ],
        satellite: [
            'satellite'
        ]
    },
    iicepLayers: [
        { 'label':'Incidents', 'id':'inc_points', 'state': false, 'type':'layer', origin:'geojson'},
        { 'label':'Events', 'id':'ev_points', 'state': false, 'type':'layer', origin:'geojson'},
        { 'label':'Resource requests', 'id':'rr_points', 'state': false, 'type':'layer', origin:'geojson'},
        { 'label':'Shelters', 'id':'shelter_points', 'state': false, 'type':'layer', origin:'geojson'},
        { 'label':'Isopleths', 'id':'isopleth_polys', 'state': false, 'type':'group', 'members': ['iso_polys', 'iso_lines'], origin:'geojson'},
        { 'label':'Annotations', 'id':'annos_f', 'state': false, 'type':'group', 'members':['anno_polys_f', 'anno_lines_f', 'anno_points_f'], origin:'geojson'},
        { 'label':'Own Annotations', 'id':'annos_o', 'state': false, 'type':'group', 'members':['anno_polys_o', 'anno_lines_o', 'anno_points_o'], origin:'geojson'},
        { 'label':'ERPA', 'id':'erpa-ll-1oikwz', 'state': false, 'type':'layer', origin:'style'},
        { 'label':'Dams(Salem)', 'id':'dams1-66ktwt', 'state': false, 'type':'layer', origin:'style'},
        { 'label':'Dams(NJ)', 'id':'dams2-bcw6vq', 'state': false, 'type':'layer', origin:'style'},
        { 'label':'Sirens', 'id':'sirens-salem', 'state': false, 'type':'layer', origin:'style'},
        { 'label':'Congregate Care', 'id':'congregate-care', 'state': false, 'type':'layer', origin:'style'},
        { 'label':'Bus Routes', 'id':'bus-routes', 'state': false, 'type':'group', 'members':['bus-routes-lines', 'bus-routes-arrows'], origin:'style'}
    ],
    annoPolygons: [
        {value: "1", color: "#000000", label: "Road Closure", bcolor: "#111111"},
        {value: "2", color: "#ff8000", label: "Detour", bcolor: "#e67300"},
        {value: "3", color: "#ffff00", label: "Road Repair/Maintenance", bcolor: "#e6e600"},
        {value: "4", color: "#009933", label: "Evacuation Route", bcolor: "#006622"},
        {value: "5", color: "#cccccc", label: "Boil Water Advisory", bcolor: "#666666"},
        {value: "6", color: "#003366", label: "Flooded Area", bcolor: "#0026d4"},
        {value: "7", color: "#6699cc", label: "Flooding/High Water", bcolor: "#3973ac"},
        {value: "8", color: "#ffff99", label: "Snow/Ice", bcolor: "#ffff00"},
        {value: "9", color: "#ff0000", label: "Forrest Fire", bcolor: "#cc0000"},
    ],
    annoLines: [
        {value: "1", color: "#000000", label: "Road Closure", },
        {value: "2", color: "#ff8000", label: "Detour"},
        {value: "3", color: "#ffff00", label: "Road Repair/Maintenance"},
        {value: "4", color: "#009933", label: "Evacuation Route"},
        {value: "5", color: "#cccccc", label: "Boil Water Advisory"},
        {value: "6", color: "#003366", label: "Flooded Area"},
        {value: "7", color: "#6699cc", label: "Flooding/High Water"},
        {value: "8", color: "#ffff99", label: "Snow/Ice"},
        {value: "9", color: "#ff0000", label: "Forrest Fire"},
    ],
    annoIcons: [
        {value: "436", icon: "436.png", label: "Accident"},
        {value: "435", icon: "435.png", label: "Community event"},
        {value: "434", icon: "434.png", label: "Community center"},
        {value: "433", icon: "433.png", label: "Municipal facility"},
        {value: "432", icon: "432.png", label: "Voting Location"},
        {value: "431", icon: "431.png", label: "Detour in effect"},
        {value: "430", icon: "430.png", label: "Road Repair"},
        {value: "429", icon: "429.png", label: "Construction Zone"},
        {value: "428", icon: "428.png", label: "Recycling Collection Site"},
        {value: "427", icon: "427.png", label: "Debris Collection Site"},
        {value: "426", icon: "426.png", label: "Parks and Open Space"},
        {value: "425", icon: "425.png", label: "Recreational Facility/Field"},
        {value: "424", icon: "424.png", label: "Emergency Operations Center"},
        {value: "423", icon: "423.png", label: "Base Camp"},
        {value: "422", icon: "422.png", label: "Incident Command Center"},
        {value: "421", icon: "421.png", label: "Staging Area"},
        {value: "420", icon: "420.png", label: "Fire Station"},
        {value: "419", icon: "419.png", label: "Fire-EMS Station"},
        {value: "418", icon: "418.png", label: "Forest Fire"},
        {value: "417", icon: "417.png", label: "Major Fire - Seek Detour"},
        {value: "416", icon: "416.png", label: "Flooded Area-Large Area"},
        {value: "415", icon: "415.png", label: "Hospital"},
        {value: "414", icon: "414.png", label: "Medical Facility"},
        {value: "412", icon: "412.png", label: "Police Station"},
        {value: "411", icon: "411.png", label: "Reunification Site"},
        {value: "410", icon: "410.png", label: "School"},
        {value: "409", icon: "409.png", label: "Shelter"},
        {value: "408", icon: "408.png", label: "EMS Station"},
        {value: "407", icon: "407.png", label: "Flooded Area"},
        {value: "406", icon: "406.png", label: "Helispot"},
        {value: "405", icon: "405.png", label: "Helibase"},
        {value: "404", icon: "404.png", label: "Camp"},
        {value: "403", icon: "403.png", label: "Boil Water Advisory"},
        {value: "402", icon: "402.png", label: "Briefing"},
        {value: "401", icon: "401.png", label: "Nuclear Power Plant"}
    ],
    incidentIcons : [
        { value: "101", icon: "101.png", label: "Abandoned Vehicle - Private Property" },
        { value: "102", icon: "102.png", label: "Autumn Leaf Collection" },
        { value: "104", icon: "104.png", label: "Down Wires - Private Property" },
        { value: "105", icon: "105.png", label: "Electric Interruption - Private Property" },
        { value: "106", icon: "106.png", label: "Fallen Tree - Private Property" },
        { value: "107", icon: "107.png", label: "Flooding/High Water - Private Property" },
        { value: "108", icon: "108.png", label: "Illegal Dumping" },
        { value: "109", icon: "109.png", label: "Recycling Collection" },
        { value: "110", icon: "110.png", label: "Trash Collection" },
        { value: "111", icon: "111.png", label: "Grafitti" },
        { value: "112", icon: "112.png", label: "Bulk Trash Collection" },
        { value: "113", icon: "113.png", label: "Utility Outage/Problem - Private property" },
        { value: "114", icon: "114.png", label: "Other (Township Services)" },
        { value: "115", icon: "115.png", label: "Bagged Leaves/Yard Debris" },
        { value: "201", icon: "201.png", label: "Dead Animal" },
        { value: "202", icon: "202.png", label: "Rodent Control" },
        { value: "203", icon: "203.png", label: "Sanitation Enforcement" },
        { value: "204", icon: "204.png", label: "Other (Public Health)" },
        { value: "301", icon: "301.png", label: "Abandoned Vehicle / Public Property" },
        { value: "302", icon: "302.png", label: "Down Wires" },
        { value: "303", icon: "303.png", label: "Dangerous Road Conditions" },
        { value: "304", icon: "304.png", label: "Debris in Roadway" },
        { value: "305", icon: "305.png", label: "Electric Interruption" },
        { value: "306", icon: "306.png", label: "Fallen Tree In Roadway" },
        { value: "307", icon: "307.png", label: "Flooding/High Water" },
        { value: "308", icon: "308.png", label: "Hazardous Condition/Spill" },
        { value: "309", icon: "309.png", label: "Pothole(s) in Roadway" },
        { value: "310", icon: "310.png", label: "Railroad Crossing - Defective Lights" },
        { value: "311", icon: "311.png", label: "Road or Intersection Closure" },
        { value: "312", icon: "312.png", label: "Signage Repair/Maint." },
        { value: "313", icon: "313.png", label: "Snow /Ice Removal" },
        { value: "314", icon: "314.png", label: "Street & Road Repair/Maint." },
        { value: "315", icon: "315.png", label: "Structural Condition - Bridge/Overpass" },
        { value: "316", icon: "316.png", label: "Traffic Light Outage" },
        { value: "317", icon: "317.png", label: "Utility Outage/Problem-Public property" },
        { value: "318", icon: "318.png", label: "OTHER (Public Safety)" },
        { value: "319", icon: "319.png", label: "Street Light Outage/Problem" },
        { value: "320", icon: "320.png", label: "Suspicious Activity" },
        { value: "321", icon: "321.png", label: "Accident-Vehicle" }
    ],
    eventIcons : [
        { value: "991", icon: "eventIcon2.png", label: "Event 1" },
        { value: "992", icon: "ev0.png", label: "Event 2" },
        { value: "993", icon: "ev1.png", label: "Event 3" },
        { value: "994", icon: "eventIcon2.png", label: "Event 4" },
        { value: "rr_red", icon: "rr_red.png", label: "RR Own" }
    ],
    drawStyles: [
        {
            'id': 'highlight-active-points',
            'type': 'symbol',
            'filter': ['all',
                ['==', '$type', 'Point'],
                ['==', 'meta', 'feature'],
                ['==', 'active', 'true']],
            'paint': {
                'icon-opacity': 1
            },
            'layout': {
                'icon-allow-overlap': true,
                'icon-anchor': 'center',
                'icon-ignore-placement': true,
                'icon-size': 1.2,
                'icon-image': ['get','user_icon']
            }
        },
        {
            'id': 'points-are-icons',
            'type': 'symbol',
            'filter': ['all',
                ['==', '$type', 'Point'],
                ['==', 'meta', 'feature'],
                ['==', 'active', 'false']],
            'paint': {
                'icon-opacity': 1
            },
            'layout': {
                'icon-allow-overlap': true,
                'icon-anchor': 'center',
                'icon-ignore-placement': true,
                'icon-image': ['get','user_icon']
            }
        },
        {
            "id": "gl-draw-line",
            "type": "line",
            "filter": ["all", ["==", "$type", "LineString"]],
            "layout": {
                "line-cap": "round",
                "line-join": "round"
            },
            "paint": {
                "line-color": ["coalesce", ["get", "user_lineColor"], "#AAAAAA"],
                "line-dasharray": [3, 2],
                "line-width": 3
            }
        },
        {
            "id": "gl-draw-poly",
            "type": "fill",
            "filter": ["all", ["==", "$type", "Polygon"]],
            "layout": {
            },
            "paint": {
                "fill-color": ["coalesce", ["get", "user_fillColor"], "#AAAAAA"],
                "fill-outline-color": ["coalesce", ["get", "user_lineColor"], "#BBBBBB"],
                "fill-opacity": 0.4
            }
        },
        {
            "id": "gl-draw-line-and-line-vertex-halo",
            "type": "circle",
            "filter": ["all",
                ["==", "meta", "vertex"],
                ['==', 'active', 'false'],
                ["==", "$type", "Point"]],
            "paint": {
                "circle-radius": 5,
                "circle-color": "#D20C0C"
            }
        },
        {
            "id": "gl-draw-line-and-line-vertex",
            "type": "circle",
            "filter": ["all",
                ["==", "meta", "vertex"],
                ['==', 'active', 'false'],
                ["==", "$type", "Point"]],
            "paint": {
                "circle-radius": 3,
                "circle-color": "#FF0",
            }
        },
        {
            "id": "gl-draw-line-and-line-vertex-halo-active",
            "type": "circle",
            "filter": ["all",
                ["==", "meta", "vertex"],
                ['==', 'active', 'true'],
                ["==", "$type", "Point"]],
            "paint": {
                "circle-radius": 7,
                "circle-color": "#D20C0C"
            }
        },
        {
            "id": "gl-draw-line-and-line-vertex-active",
            "type": "circle",
            "filter": ["all",
                ["==", "meta", "vertex"],
                ['==', 'active', 'true'],
                ["==", "$type", "Point"]],
            "paint": {
                "circle-radius": 5,
                "circle-color": "#FF0",
            }
        },
        {
            'id': 'gl-draw-line-midpoint',
            'type': 'circle',
            'filter': ['all',
                ['==', '$type', 'Point'],
                ['==', 'meta', 'midpoint']],
            'paint': {
                'circle-radius': 3,
                'circle-color': '#820C0C'
            }
        },
    ],
    mapInitialView: [[-75.5596, 39.3803], [-75.061854, 39.787539]],
    iso_polys: {
       attrib: {
           'event_name': 'Event Name'
        }
    },
    iso_lines: {
        attrib: {
            'event_name': 'Event Name'
        }
    },
    anno_points_f: {
        attrib: {
            'aType': 'Type',
            'Owner': 'Owner'
        }
    },
    anno_lines_f: {
        attrib: {
            'aType': 'Type',
            'Owner': 'Owner'
        }
    },
    anno_polys_f: {
        attrib: {
            'aType': 'Type',
            'Owner': 'Owner'
        }
    },
    anno_points_o: {
        attrib: {
            'aType': 'Type',
        }
    },
    anno_lines_o: {
        attrib: {
            'aType': 'Type',
        }
    },
    anno_polys_o: {
        attrib: {
            'aType': 'Type',
        }
    },
    inc_points: {
        attrib: {
            'iid': 'ID',
            'itypename': 'Incident Type',
            'address': 'Address',
            'statustext': 'Status',
            'descript': 'Description',
            'updated': 'Last update',
            'township': 'Owner'
        }
    },
    ev_points: {
        attrib: {
            'ename': 'Event Name',
            'evtypename': 'Event Type',
            'prognosistxt': 'Prognosis',
            'impacttxt': 'Impact',
            'statustxt': 'Status',
            'address': 'Address'
        }
    },
    rr_points: {
        attrib: {
            idx:"Request ID",
            jname:"Jurisdiction",
            rneedtxt:"Resource Need",
            rneedescr:"Description",
            severitytext:"Severity",
            from:"From",
            to:"To"
        }
    },
    shelter_points: {
        attrib: {
        }
    },
    'erpa-ll-1oikwz': {
        attrib: {
            'LABEL': 'Zone:'
        }
    },
    'dams1-66ktwt': {
        attrib: {
            "BASIN":"BASIN",
            "BLOCK":"BLOCK",
            "CITY_TOWN":"CITY_TOWN",
            "CLASS":"CLASS",
            "COUNTY":"COUNTY",
            "DAM_NAME":"DAM_NAME",
            "DAM_TYPE":"DAM_TYPE",
            "DEP_OWNED":"DEP_OWNED",
            "DIKE":"DIKE",
            "DRAINAGE_M":"DRAINAGE_M",
            "FEDERAL_ID":"FEDERAL_ID",
            "HEIGHT_FT":"HEIGHT_FT",
            "LARGE_DAM":"LARGE_DAM",
            "LAST_UPDAT":"LAST_UPDATE",
            "LENGTH_FT":"LENGTH_FT",
            "LOT":"LOT",
            "MAXCAP_ACF":"MAXCAP_ACF",
            "NORMAL_CAP":"NORMAL_CAP",
            "NS_ACRES":"NS_ACRES",
            "OWNERSHIP":"OWNERSHIP",
            "OWNER_TYPE":"OWNER_TYPE",
            "PI_ID":"PI_ID",
            "PURPOSES":"PURPOSES",
            "RIVER_STRM":"RIVER_STRM",
            "SDF":"SOF",
            "SITE_ID":"SITE_ID",
            "SPILLWAY":"SPILLWAY",
            "SPILL_CAP":"SPILL_CAP",
            "X_COORDINA":"X COORDINATE",
            "Y_COORDINA":"Y COORDINATE",
            "LONGITUDE_":"LONGITUDE_D",
            "LATITUDE_D":"LATITUDE_D" ,
            "YRCOMPLETE":"YRCOMPLETE",
        }
    },
    'sirens-salem': {
        attrib: {
            "LABEL_NUM":"NUMBER",
            "LAT":"LAT",
            "LON":"LON",
            "NAME2":"NAME"
        }
    },
    'congregate-care': {
        attrib: {
            "ADDRESS":"ADDRESS",
            "ADDRESS2":"ADDRESS",
            "CITY":"CITY",
            "LABEL_NO":"LABEL_NO",
            "LAT":"LAT",
            "LON":"LON",
            "MUNICIPALI":"MUNICIPALITY",
            "NAME2":"NAME",
            "PHONE":"PHONE",
            "STATE":"STATE",
            "ZIPCODE":"ZIP",
            "l_side":"l_side"
        }
    },
    'bus-routes-lines': {
        attrib: {
            'ROUTE': 'ROUTE'
        }
    },
    'bus-routes-arrows': {
        attrib: {
            'ROUTE': 'ROUTE'
        }
    },
    'dams2-bcw6vq': {
        attrib: {
            "BASIN":"BASIN",
            "BLOCK":"BLOCK",
            "CITY_TOWN":"CITY_TOWN",
            "CLASS":"CLASS",
            "COUNTY":"COUNTY",
            "DAM_NAME":"DAM_NAME",
            "DAM_TYPE":"DAM_TYPE",
            "DEP_OWNED":"DEP_OWNED",
            "DIKE":"DIKE",
            "DRAINAGE_M":"DRAINAGE_M",
            "FEDERAL_ID":"FEDERAL_ID",
            "HEIGHT_FT":"HEIGHT_FT",
            "LARGE_DAM":"LARGE_DAM",
            "LAST_UPDAT":"LAST_UPDATE",
            "LENGTH_FT":"LENGTH_FT",
            "LOT":"LOT",
            "MAXCAP_ACF":"MAXCAP_ACF",
            "NORMAL_CAP":"NORMAL_CAP",
            "NS_ACRES":"NS_ACRES",
            "OWNERSHIP":"OWNERSHIP",
            "OWNER_TYPE":"OWNER_TYPE",
            "PI_ID":"PI_ID",
            "PURPOSES":"PURPOSES",
            "RIVER_STRM":"RIVER_STRM",
            "SDF":"SOF",
            "SITE_ID":"SITE_ID",
            "SPILLWAY":"SPILLWAY",
            "SPILL_CAP":"SPILL_CAP",
            "X_COORDINA":"X COORDINATE",
            "Y_COORDINA":"Y COORDINATE",
            "LONGITUDE_":"LONGITUDE_D",
            "LATITUDE_D":"LATITUDE_D" ,
            "YRCOMPLETE":"YRCOMPLETE",
        }
    },
    layerdefs: [
        {
            id: 'inc_points',
            type: 'symbol',
            source: 'inc_ftrs_src',
            paint: {
                'icon-opacity': 1
            },
            layout: {
                'icon-allow-overlap': true,
                'icon-anchor': 'center',
                'icon-ignore-placement': true,
                'icon-image': ['get','icon'],
                'visibility': 'visible'
            }
        },
        {
            id: 'ev_points',
            type: 'symbol',
            source: 'ev_ftrs_src',
            paint: {
                'icon-opacity': 1
            },
            layout: {
                'icon-allow-overlap': true,
                'icon-anchor': 'center',
                'icon-ignore-placement': true,
                'icon-image': ['get','icon'],
                'visibility': 'visible'
            }
        },
        {
            id: 'rr_points',
            type: 'symbol',
            source: 'rr_ftrs_src',
            paint: {
                'icon-opacity': 1
            },
            layout: {
                'icon-allow-overlap': true,
                'icon-anchor': 'center',
                'icon-ignore-placement': true,
                'icon-image': 'imrr_red',
                'visibility': 'visible'
            }
        },
        {
            id: 'shelter_points',
            type: 'symbol',
            source: 'shelters_src',
            paint: {
                'icon-opacity': 1
            },
            layout: {
                'icon-allow-overlap': true,
                'icon-anchor': 'center',
                'icon-ignore-placement': true,
                'icon-image': ['get','icontype'],
                'visibility': 'visible'
            }
        },
        {
            id: 'iso_polys',
            type: 'fill',
            source: 'iso_ftrs_src',
            paint: {
                "fill-color": ["coalesce", ["get", "fillColor"], "#AAAAAA"],
                'fill-opacity': ["coalesce", ["get", "fillOpacity"], 0.5]
            }
        },
        {
            id: 'iso_lines',
            type: 'line',
            source: 'iso_ftrs_src',
            paint: {
                "line-color": ["coalesce", ["get", "strokeColor"], "#AAAAAA"],
                'line-width': ["coalesce", ["get", "strokeWeight"], 2],
                'line-opacity': ["coalesce", ["get", "strokeOpacity"], 1]
            }
        },
        {
            id: 'anno_points_o',
            type: 'symbol',
            source: 'anno_own_src',
            filter: ['==', '$type', 'Point'],
            paint: {
                'icon-opacity': 1
            },
            layout: {
                'icon-allow-overlap': true,
                'icon-anchor': 'center',
                'icon-ignore-placement': true,
                'icon-image': ['get','icon'],
                'visibility': 'visible'
            }
        },
        {
            id: 'anno_lines_o',
            type: 'line',
            source: 'anno_own_src',
            filter: ["any", ["==", "$type", "LineString"], ["==", "$type", "Polygon"]],
            paint: {
                "line-color": ["coalesce", ["get", "lineColor"], "#AAAAAA"],
                'line-width': 4
            }
        },
        {
            id: 'anno_polys_o',
            type: 'fill',
            source: 'anno_own_src',
            filter: ["all", ["==", "$type", "Polygon"]],
            paint: {
                "fill-color": ["coalesce", ["get", "fillColor"], "#AAAAAA"],
                'fill-opacity': 0.4
            }
        },
        {
            id: 'anno_points_f',
            type: 'symbol',
            source: 'anno_foreign_src',
            filter: ['==', '$type', 'Point'],
            paint: {
                'icon-opacity': 1
            },
            layout: {
                'icon-allow-overlap': true,
                'icon-anchor': 'center',
                'icon-ignore-placement': true,
                'icon-image': ['get','icon'],
                'visibility': 'visible'
            }
        },
        {
            id: 'anno_lines_f',
            type: 'line',
            source: 'anno_foreign_src',
            filter: ["any", ["==", "$type", "LineString"], ["==", "$type", "Polygon"]],
            paint: {
                "line-color": ["coalesce", ["get", "lineColor"], "#AAAAAA"],
                'line-width': 4
            }
        },
        {
            id: 'anno_polys_f',
            type: 'fill',
            source: 'anno_foreign_src',
            filter: ["all", ["==", "$type", "Polygon"]],
            paint: {
                "fill-color": ["coalesce", ["get", "fillColor"], "#AAAAAA"],
                'fill-opacity': 0.4
            }
        },
    ],
    sourcedefs: {
        inc_ftrs_src: {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        },
        ev_ftrs_src: {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        },
        rr_ftrs_src: {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        },
        shelters_src: {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        },
        iso_ftrs_src: {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        },
        anno_own_src: {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        },
        anno_foreign_src: {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        },
    },
}