<script>
import {mapActions} from 'vuex';
import ConfirmDialogue from "@/components/ConfirmDialogue.vue";
import {saveData} from "@/services.js";

export default {
  components: {ConfirmDialogue},
  mounted() {
    this.pullRepoFiles();
  },
  methods: {
    ...mapActions(['pullRepoFiles']),
    closeWin: function () {
      this.dialog=false;
    },
    async confirmDeleteRepoDoc(docid,docname) {
      try {
        const result = await this.$refs.confirmRepoDocDialog.open({
          title: 'Confirm Document Deletion',
          message: `Dashboard Document <strong>${docname}</strong> will be deleted`
        });
        if (result) {
          const fd = new FormData();
          fd.append('mode', 'delrepodoc');
          fd.append('docid', docid);
          await saveData(fd);
        }
      } catch (err) {
      }
    },
  },
  computed: {
    dlgwid() {
      if (this.$vuetify.display.name==='xs') {
        return '90vw';
      } else if (this.$vuetify.display.name==='sm') {
        return '80vw';
      } else if (this.$vuetify.display.name==='md') {
        return '60vw';
      } else if (this.$vuetify.display.name==='lg') {
        return '50vw';
      } else {
        return '50vw';
      }
    },
    fileList() {
      let df=[]
      switch (this.repoid) {
        case 'sop':
          df = this.$store.state.repofiles.SOP;
          break;
        case 'eop':
          df = this.$store.state.repofiles.EOP;
          break;
        case 'iap':
          df = this.$store.state.repofiles.IAP;
          break;
        case 'repo':
          df = this.$store.state.repofiles.REPO;
          break;
        case 'ler':
          df = this.$store.state.repofiles.LER;
          break;
      }
      return df;
    },
  },
  data: () => ({
    repoid: 'sop',
    tab: null,
    text: null,
    dialog: false,
  }),
}
</script>
<template>
    <v-dialog
        theme="light"
        v-model="dialog"
        persistent
        :width="dlgwid"
    >
      <template v-slot:activator="{ props }">
        <v-btn
            size="small"
            class="text-right"
            v-bind="props"
            variant="outlined"
        > Browse
        </v-btn>
      </template>
      <v-card
          id="repocard"
          class="pb-0" width="55vw">
        <v-card-text>
          <v-btn-toggle
              v-model="repoid"
              rounded="0"
              theme="default"
              color="blue-darken-3"
              group
              density="comfortable"
          >
            <v-btn value="sop">
              RERP SOP
            </v-btn>
            <v-btn value="eop">
              EOP
            </v-btn>
            <v-btn value="iap">
              IAP
            </v-btn>
            <v-btn value="repo">
              Files
            </v-btn>
            <v-btn value="ler">
              LE
            </v-btn>
          </v-btn-toggle>
          <v-container>
            <v-table
                id="fileTableSOP"
                class="file_Table"
                density="comfortable"
                fixed-header
                height="65vh">
              <thead>
              <tr>
                <th class="text-left"> Owner</th>
                <th class="text-left"> Title</th>
                <th class="text-left"> Date</th>
                <th class="text-left"> Rev</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in fileList"
                  :key="item.ID"
              >
                <td>{{ item.Owner }}</td>
                <td><a :href="$backendUrl+'getRepoFile.php?id='+item.ID">{{ item.Title }}</a></td>
                <td>{{ item.DDate }}</td>
                <td>{{ item.Rev }}</td>
                <th>
                  <v-btn
                      v-if="['B','C','D','G'].includes($store.state._main_accessLevel)"
                      theme="light"
                      variant="flat"
                      icon = "mdi-delete-outline"
                      size="small"
                      density="compact"
                      @click="confirmDeleteRepoDoc(item.ID,item.Title)"
                  >
                  </v-btn>
                </th>
              </tr>
              </tbody>
            </v-table>
            <ConfirmDialogue ref="confirmRepoDocDialog" />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
              density="default"
              variant="outlined"
              @click="closeWin"
          > Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<style scoped>
</style>