<template>
  <v-dialog
      v-model="afdialog"
      persistent
      theme="light"
      width="90%"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          class="text-right"
          size="small"
          v-bind="props"
          variant="flat"
          @click="loadData"
      > {{ jname }} EOC Activation Form
      </v-btn>
    </template>

    <v-card
        class="elevation-12"
        color="white"
        min-width="500"
        variant="flat"
        width="100%"
    >
      <v-toolbar color="#D32F2F" dark flat>
        <v-toolbar-title>Salem County OEM Municipal EOC Activation Form</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col class="pa-0" cols="12">
              <v-card-text class="font-weight-black text-subtitle-1 pa-0">
                Section 1
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section1.EOCTown"
                  hint="EOC/Town"
                  persistent-hint
                  placeholder="EOC/Town"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section1.timeEOCActivated"
                  hint="Time EOC Partially Activated"
                  persistent-hint
                  placeholder="Time EOC Partially Activated"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section1.actDate"
                  hint="Date"
                  persistent-hint
                  placeholder="Date"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section1.timeEOCFullyActivated"
                  hint="Time EOC Fully Activated"
                  persistent-hint
                  placeholder="Time EOC Fully Activated"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section1.nameOfPerson"
                  hint="Name of Person Notified at Municipal OEM"
                  persistent-hint
                  placeholder="Name of Person Notified at Municipal OEM"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section1.EOCClosedDate"
                  hint="EOC Closed Date"
                  persistent-hint
                  placeholder="EOC Closed Date"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section1.timeNotificationCounty"
                  hint="Time of Notificaton from County"
                  persistent-hint
                  placeholder="Time of Notificaton from County"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section1.EOCClosedTime"
                  hint="EOC Closed Time"
                  persistent-hint
                  placeholder="EOC Closed Time"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0" cols="12">
              <v-card-text class="font-weight-black text-subtitle-1 pa-0">
                Activity/Purpose for Activation (i.e., REP Exercise, Snow Storm)
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section1.reason"
                  hint="Reason for Activation (Drill/Exercise/Event)"
                  persistent-hint
                  placeholder="Reason for Activation (Drill/Exercise/Event)"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section1.eventName"
                  hint="Major Event Name"
                  persistent-hint
                  placeholder="Major Event Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0" cols="12">
              <v-card-text class="font-weight-black text-subtitle-1 pa-0">
                Section 2
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0" cols="4">
              <v-card-text
                  class="font-weight-black text-subtitle-1"
              >Initial Checks
              </v-card-text>
            </v-col>
            <v-col class="pt-0" cols="4">
              <v-card-text
                  class="font-weight-black text-subtitle-1"
              >Time Sent/Rec'd
              </v-card-text>
            </v-col>
            <v-col class="pt-0" cols="4">
              <v-card-text
                  class="font-weight-black text-subtitle-1"
              >Confirmed by
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="4">
              <v-text-field
                  v-model="section2.faxTestRec"
                  hint="Fax Test Received (Yes/No)"
                  persistent-hint
                  placeholder="Fax Test Received (Yes/No)"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="4">
              <v-text-field
                  v-model="section2.timeFaxTestRec"
                  hint="Time Fax Test Received"
                  persistent-hint
                  placeholder="Time Fax Test Received"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="4">
              <v-text-field
                  v-model="section2.personFaxTestRec"
                  hint="Name of Person Receiving Fax Test"
                  persistent-hint
                  placeholder="Name of Person Receiving Fax Test"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="4">
              <v-text-field
                  v-model="section2.testFaxRet"
                  hint="Did you Send Test Fax Back to County? (Yes/No)"
                  persistent-hint
                  placeholder="Did you Send Test Fax Back to County? (Yes/No)"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="4">
              <v-text-field
                  v-model="section2.timeTestFaxRet"
                  hint="Time Fax Test Returned"
                  persistent-hint
                  placeholder="Time Fax Test Returned"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="4">
              <v-text-field
                  v-model="section2.personTestFaxRet"
                  hint="Name of Person Sending Test Fax"
                  persistent-hint
                  placeholder="Name of Person Sending Test Fax"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="4">
              <v-text-field
                  v-model="section2.actNoticeRec"
                  hint="Did you receive EOC Activation Notice from County? (Yes/No)"
                  persistent-hint
                  placeholder="Did you receive EOC Activation Notice from County? (Yes/No)"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="4">
              <v-text-field
                  v-model="section2.timeActNoticeRec"
                  hint="Time Activation Notice received"
                  persistent-hint
                  placeholder="Time Activation Notice received"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="4">
              <v-text-field
                  v-model="section2.personActNoticeRec"
                  hint="Name of Person receiving Activation Notice"
                  persistent-hint
                  placeholder="Name of Person receiving Activation Notice"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="4">
              <v-text-field
                  v-model="section2.testEmailRec"
                  hint="Did you receive Test Email from County? (Yes/No)"
                  persistent-hint
                  placeholder="Did you receive Test Email from County? (Yes/No)"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="4">
              <v-text-field
                  v-model="section2.timeTestEmailRec"
                  hint="Time Test Email received"
                  persistent-hint
                  placeholder="Time Test Email received"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="4">
              <v-text-field
                  v-model="section2.personTestEmailRec"
                  hint="Name of Person Receiving Test Email"
                  persistent-hint
                  placeholder="Name of Person Receiving Test Email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0" cols="12">
              <v-card-text class="font-weight-black text-subtitle-1 pa-0">
                Section 3
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0" cols="12">
              <v-card-text class="font-weight-black text-subtitle-1 pa-0">
                Confirmation of Contact Information for this event
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section3.muniPhoneNumber"
                  hint="Municipal EOC Phone Number"
                  persistent-hint
                  placeholder="Municipal EOC Phone Number"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="6" sm="3">
              <v-text-field
                  v-model="section3.muniAltPhoneNumber"
                  hint="Alternate Phone Number"
                  persistent-hint
                  placeholder="Alternate Phone Number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section3.muniFaxNumber"
                  hint="Municipal Fax Number"
                  persistent-hint
                  placeholder="Municipal Fax Number"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="6" sm="3">
              <v-text-field
                  v-model="section3.muniAltFaxNumber"
                  hint="Municipal Second Fax Number"
                  persistent-hint
                  placeholder="Municipal Second Fax Number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="6" sm="3">
              <v-text-field
                  v-model="section3.muniCoordName"
                  hint="Municipal EOC Coordinator Name"
                  persistent-hint
                  placeholder="Municipal EOC Coordinator Name"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="6" sm="3">
              <v-text-field
                  v-model="section3.muniCoordEmail"
                  hint="Coordinator Email"
                  persistent-hint
                  placeholder="Coordinator Email"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="6" sm="3">
              <v-text-field
                  v-model="section3.deskPhoneNumber"
                  hint="Desk Phone Number"
                  persistent-hint
                  placeholder="Desk Phone Number"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="6" sm="3">
              <v-text-field
                  v-model="section3.muniCoordCellNumber"
                  hint="Coordinator Cell Number"
                  persistent-hint
                  placeholder="Coordinator Cell Number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="6" sm="3">
              <v-text-field
                  v-model="section3.muniDepCoordName"
                  hint="Deputy EOC Coordinator Name"
                  persistent-hint
                  placeholder="Deputy EOC Coordinator Name"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="6" sm="3">
              <v-text-field
                  v-model="section3.muniDepCoordEmail"
                  hint="Deputy Email"
                  persistent-hint
                  placeholder="Deputy Email"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="6" sm="3">
              <v-text-field
                  v-model="section3.depDeskPhoneNumber"
                  hint="Desk Phone Number"
                  persistent-hint
                  placeholder="Desk Phone Number"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="6" sm="3">
              <v-text-field
                  v-model="section3.depCellNumber"
                  hint="Deputy Cell Number"
                  persistent-hint
                  placeholder="Deputy Cell Number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section3.muniPhoneOpName"
                  hint="Municipal Phone Operator Name"
                  persistent-hint
                  placeholder="Municipal Phone Operator Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section3.countyPhoneOpName"
                  hint="County Phone Operator Name"
                  persistent-hint
                  placeholder="County Phone Operator Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="6">
              <v-text-field
                  v-model="section3.backupCheck"
                  hint="Backup Means of Communication Check (Yes/No)"
                  persistent-hint
                  placeholder="Backup Means of Communication Checked (Yes/No)"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="3">
              <v-text-field
                  v-model="section3.radioCheck"
                  hint="Radio Check (Yes/No)"
                  persistent-hint
                  placeholder="Radio Check (Yes/No)"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="3">
              <v-text-field
                  v-model="section3.RACES"
                  hint="RACES (Operator Name/Call Sign)"
                  persistent-hint
                  placeholder="RACES (Operator Name/Call Sign)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0" cols="12">
              <v-card-text class="font-weight-regular text-subtitle-2 pa-0">
                Valid Radios are: 800/500 MHz and/or EMRAD
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                  v-model="section4.Notes"
                  background-color="amber lighten-4"
                  color="orange orange-darken-4"
                  label="Notes"
                  outlined
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            @click="closeForm"
        >Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            v-show="canSave"
            color="primary"
            @click="saveForm"
        >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {getEocAf} from "@/services.js";

export default {
  name: 'eocaf',
  data: () => ({
    afdialog: false,
    section0: {
      afId: -1,
      juriId1: '',
      juriId2: '',
    },
    section1: {
      EOCTown: '11',
      actDate: '12',
      timeEOCActivated: '13',
      timeEOCFullyActivated: '14',
      nameOfPerson: '15',
      EOCClosedDate: '16',
      timeNotificationCounty: '17',
      EOCClosedTime: '18',
      reason: '19',
      eventName: '10',
    },
    section2: {
      faxTestRec: '21',
      timeFaxTestRec: '22',
      personFaxTestRec: '23',
      testFaxRet: '24',
      timeTestFaxRet: '25',
      personTestFaxRet: '26',
      actNoticeRec: '27',
      timeActNoticeRec: '28',
      personActNoticeRec: '29',
      testEmailRec: '2a',
      timeTestEmailRec: '2b',
      personTestEmailRec: '2c'
    },
    section3: {
      muniPhoneNumber: '31',
      muniAltPhoneNumber: '32',
      muniFaxNumber: '33',
      muniAltFaxNumber: '34',
      muniCoordName: '35',
      muniCoordEmail: '36',
      deskPhoneNumber: '37',
      muniCoordCellNumber: '38',
      muniDepCoordName: '39',
      muniDepCoordEmail: '3a',
      depDeskPhoneNumber: '3b',
      depCellNumber: '3c',
      muniPhoneOpName: '3d',
      countyPhoneOpName: '3e',
      backupCheck: '3f',
      radioCheck: '3g',
      RACES: '3h'
    },
    section4: {
      Notes: '41'
    },
    canSave: false
  }),
  props: {
    eventId: Number,
    mcode: String,
    jname: String,
  },
  computed: {},
  methods: {
    closeForm() {
      this.afdialog = false;
      this.canSave = false;
    },
    async loadData() {
      let self = this;
      try {
        await getEocAf(this.mcode, this.eventId)
            .then(data => {
              self.canSave = (data.o === 1);
              Object.keys(self.section0).forEach(key => {
                self.section0[key] = data.data[key];
              });
              Object.keys(self.section1).forEach(key => {
                self.section1[key] = data.data[key];
              });
              Object.keys(self.section2).forEach(key => {
                self.section2[key] = data.data[key];
              });
              Object.keys(self.section3).forEach(key => {
                self.section3[key] = data.data[key];
              });
              Object.keys(self.section4).forEach(key => {
                self.section4[key] = data.data[key];
              });
            });
      } catch (err) {
        console.log(err);
      }
    },
    async saveForm() {
      let self = this;
      const formData = new FormData();
      Object.keys(self.section0).forEach(key => {
        formData.append(key, self.section0[key]);
      });
      Object.keys(self.section1).forEach(key => {
        formData.append(key, self.section1[key]);
      });
      Object.keys(self.section2).forEach(key => {
        formData.append(key, self.section2[key]);
      });
      Object.keys(self.section3).forEach(key => {
        formData.append(key, self.section3[key]);
      });
      Object.keys(self.section4).forEach(key => {
        formData.append(key, self.section4[key]);
      });
      try {
        const {data} = await Axios.post('saveaf.php', formData);
        if (data.Status === 'OK') {
          window.close();
        } else {
          alert("Error occurred!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted: function () {
  }
}
</script>

<style>
</style>
