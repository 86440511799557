<script>
import eocaf from "@/components/eoc-af.vue";
export default {
  name: "aforms",
  components: {eocaf},
  data: () => ({}),
  methods: {},
  computed: {
    aformList() {
      return this.$store.state._edb_afs;
    }
  }
}
</script>
<template>
    <div class="maf">
        <h3 >MUNICIPAL ACTIVATION FORMS</h3>
        <div class="aformtab">
            <table class="tab">
                <tbody>
                <tr class="hdrtab">
                    <th>Township</th>
                </tr>
                <tr v-for="entry in aformList">
                    <td class="td3">
                        <span><eocaf :jname="entry.EOCTown" :mcode="entry.juriId2" :event-id="$store.state._ICS214_eventId"/></span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<style scoped>
    .tab{
        width: 100%;
    }
    .hdrtab {
        background-color:#7c93c0
    }
    h3 {
        text-align: left;
        padding-left:10px;
    }
    .td3 {
        font-size: 16px;
        padding : 5px 5px 5px 15px;
        font-style: normal;
        font-weight: bold;
        text-align: left;
    }
    .maf {
        display: flex;
        height: 100%;
        flex-direction: column;
    }
    .maf h3 {
        height: 25px;
        color: #ff0000;
    }
    .aformtab {
        width:100%;
        flex: 1;
        overflow-y: auto;
    }
</style>
